body {
	overflow-x: hidden;
}

img {
	max-width: 100%;
	max-width: 100%;
	height: auto;
}

label {
	display: block;
}

a {
	text-decoration-skip-ink: auto;

	&:hover {
		color: map-get($theme-colors, secondary);
		text-decoration: none;
	}
}

.borders {
	border: 1px solid rgb(86 61 124 / 15%);
}

// * {
//  transition  : all .3s ease;
// }
img.rounded {
	border-radius: 0.7rem !important;
}

.underlined {
	position: relative;

	&::after {
		content: '';
		display: block;
		position: absolute;
		top: 90%;
		left: 0;
		width: 100%;
		height: 3px;
		transition: all 0.15s ease-out;
		background: $h-gradient;
	}
}

a.underlined {
	color: map-get($theme-colors, dark);

	&::after {
		top: 110%;
	}

	&:hover {
		color: map-get($theme-colors, primary); //padding-bottom: 0;
		text-decoration: none;

		&::after {
			top: 90%;
		}
	}
}

.big {
	display: table;
	margin-right: auto;
	margin-left: auto;
	font-size: 1.5rem;
	font-weight: 600;
}

/* Change Autocomplete styles in Chrome */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	transition: background-color 5000s ease-in-out 0s;
	box-shadow: 0 0 0 1000px darken(map-get($theme-colors, secondary), 7%) inset;
}

.no-bullets li {
	list-style-type: none;
}

.reverse-image {
	transform: scaleX(-1);
	transform: scaleX(-1);
	transform: scaleX(-1);
	transform: scaleX(-1);
	filter: 'FlipH';
	filter: fliph;
}

.abs-center-v {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

.abs-center-h {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
}

table,
th,
td {
	border: 1px solid map-get($theme-colors, primary);
}