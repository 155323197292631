@import "../_sprite";

$sidebar-width: 200px;
$sidebar-visible-width: 80px;

.widget_search input[type="submit"] {
	z-index: 4;
	width: $sidebar-visible-width;
}


.social-share {
	width: $sidebar-visible-width*2;
}

.social-share-buttons {
	width: $sidebar-visible-width;
}


.header-widget-area {
	position: absolute;
	right: 0;
	height: 100%;
}

// .widget_search {
//   display: inline-block;
//   height: 100%;
//   float: right;
//   padding: 0;


//   input[type="search"] {
//     height: 100%;
//     font-size: 55px;
//     display: inline-block;
//     font-family: "Lato";
//     font-weight: 300;
//     border: none;
//     outline: none;
//     color: white;
//     padding-right: $sidebar-visible-width;
//     width: 0px;
//     opacity: 0;
//     position: absolute;
//     top: 0;
//     right: 0;

//     background-color: darken(map-get($theme-colors, secondary), 7%);
//     z-index: 3;
//     transition: all .4s cubic-bezier(0.000, 0.795, 0.000, 1.000);
//     cursor: pointer;
//     padding-left: $spacer * 1.5;
//   }

//   input[type="search"]:focus:hover {
//     border-bottom: 1px solid #BBB;
//   }

//   input[type="search"]:focus {
//     width: 700px;
//     z-index: 1;
//     opacity: 1;
//     border-bottom: 1px solid #BBB;
//     cursor: text;
//   }
//   input[type="submit"] {
//     height: 100%;
//     display: inline-block;
//     float: right;
//     background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACUAAAAmCAYAAABDClKtAAAACXBIWXMAAAsSAAALEgHS3X78AAABcUlEQVRYhe2XXZHCQBCEO1EQCZEQByAhEiLhJMQBEpDAOQAUHA4iAQl9D5m5G1KQ2tmfqjykH2Gm98vs32yFAJHsARwAdACOi79vAB4A7lVVfYf4JYnkSHJiuCaSYymYbgEzkTyR7Em2Jq6V305v4rucQIMxf5IcnLlPkx+cGwp0IdlEeDSSmw4mU6Y6Rxv9+52NX9xUmjVxSQUynlqxKSZ5NGvIPWUrvo1ZY6M3Was05AIy3oO7WrKl40ocPoZ+dB8SX2M+qQGg5Gms3ofVKFGN+eoAgHsRnFfvsF1otm1biojzyU+SdEGVAooZpy4NE6M/qNLT54mvMfdDQOgijJN639aCVDXmBg0I3K6RUu/HapRqi4fnMmkoAOS/ZiRxexeyGGyrdRGD7TV5YlSiHf6KBvoAlvpwIMmfLOuUeZ5YRwHKByaDJj1GZSrzg4m5VuP6BuSqVfyQWw4sRTuYVzuYVzuYVzuYVwYsuTvJKluhX3UdpEKqWiJgAAAAAElFTkSuQmCC');
//     background-position: center center;
//     background-repeat: no-repeat;
//     text-indent: -10000px;
//     border: none;
//     position: absolute;
//     top: 0;
//     right: 0;
//     z-index: 4;
//     cursor: pointer;
//     pointer-events: none;
//     background-color: map-get($theme-colors, secondary); //border-bottom: solid 1px darken(map-get($theme-colors, secondary), 10%);
//   }

//   .search-form:focus-within input[type="submit"] {
//     pointer-events: initial;
//   }

//   input[type="submit"]:hover {}
// }

aside.sidebar-primary {
	background-color: darken(white, 7%);

	.entry {
		background-color: white;
	}

	.widget {
		padding: $spacer;
	}

	.entry-title,
	.post-info,
	p {
		@extend .px-4;
	}

	p:last-child {
		@extend .pb-4;
	}

	.widget-title {
		color: map-get($theme-colors, dark);

		&::before {
			content: "";
			display: block;
			width: 2rem;
			height: 5px;
			margin-bottom: 1rem;
			background-color: map-get($theme-colors, primary);
		}
	}
}
