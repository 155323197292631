.footer-widgets {
	background: $h-gradient;

	ul {
		padding: 0;
		@extend .no-bullets;
	}

	ul li,
	.featured-content .entry {
		margin-bottom: $spacer;
	}
}

.bg-primary {

	a {
		color: white;

		&:hover {
			color: map-get($theme-colors, secondary);
		}
	}
}

.site-footer {
	background: $dark-h-gradient;
	color: white;
}
