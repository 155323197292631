@charset "UTF-8";
@import "../node_modules/klaro/dist/klaro.css";
@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  tr,
img {
    page-break-inside: avoid;
  }

  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }
  .table td,
.table th {
    background-color: #fff !important;
  }

  .table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }
  .table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: #dee2e6;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, .sidebar .entry-title a, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #4b5bf1;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #1125de;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #868e96;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, .sidebar .entry-title a, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .sidebar .entry-title a, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small,
.wp-caption-text {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #868e96;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #868e96;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #cdd1fb;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #a1aaf8;
}

.table-hover .table-primary:hover {
  background-color: #b5bbf9;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #b5bbf9;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #b8eff7;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #7ae2f0;
}

.table-hover .table-secondary:hover {
  background-color: #a1eaf4;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #a1eaf4;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fce7c2;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #fad28e;
}

.table-hover .table-warning:hover {
  background-color: #fbddaa;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #fbddaa;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #f9fafb;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #f4f5f7;
}

.table-hover .table-light:hover {
  background-color: #eaedf1;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #eaedf1;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-light-blue,
.table-light-blue > th,
.table-light-blue > td {
  background-color: #f9fdfe;
}
.table-light-blue th,
.table-light-blue td,
.table-light-blue thead th,
.table-light-blue tbody + tbody {
  border-color: #f3fcfd;
}

.table-hover .table-light-blue:hover {
  background-color: #e3f6fa;
}
.table-hover .table-light-blue:hover > td,
.table-hover .table-light-blue:hover > th {
  background-color: #e3f6fa;
}

.table-dark-blue,
.table-dark-blue > th,
.table-dark-blue > td {
  background-color: #bbc1cd;
}
.table-dark-blue th,
.table-dark-blue td,
.table-dark-blue thead th,
.table-dark-blue tbody + tbody {
  border-color: #818ca2;
}

.table-hover .table-dark-blue:hover {
  background-color: #acb4c2;
}
.table-hover .table-dark-blue:hover > td,
.table-hover .table-dark-blue:hover > th {
  background-color: #acb4c2;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 2rem + 2px);
  padding: 1rem 2rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #c1c7fa;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(75, 91, 241, 0.25);
}
.form-control::placeholder {
  color: #868e96;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(1rem + 1px);
  padding-bottom: calc(1rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 1rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #868e96;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 2rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.5rem) center;
  background-size: calc(0.75em + 1rem) calc(0.75em + 1rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 8rem !important;
  background-position: right 4rem center;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 2rem);
  background-position: top calc(0.375em + 0.5rem) right calc(0.375em + 0.5rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 4.5rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 2rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 3rem/calc(0.75em + 1rem) calc(0.75em + 1rem) no-repeat;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 2rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.5rem) center;
  background-size: calc(0.75em + 1rem) calc(0.75em + 1rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 8rem !important;
  background-position: right 4rem center;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 2rem);
  background-position: top calc(0.375em + 0.5rem) right calc(0.375em + 0.5rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 4.5rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 2rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 3rem/calc(0.75em + 1rem) calc(0.75em + 1rem) no-repeat;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
.form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn, .klaro .cookie-modal .cm-btn,
.klaro .context-notice .cm-btn,
.klaro .cookie-notice .cm-btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 1rem 2rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 2rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn, .klaro .cookie-modal .cm-btn,
.klaro .context-notice .cm-btn,
.klaro .cookie-notice .cm-btn {
    transition: none;
  }
}
.btn:hover, .klaro .cookie-modal .cm-btn:hover,
.klaro .context-notice .cm-btn:hover,
.klaro .cookie-notice .cm-btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn:focus, .klaro .cookie-modal .cm-btn:focus,
.klaro .context-notice .cm-btn:focus,
.klaro .cookie-notice .cm-btn:focus, .btn.focus, .klaro .cookie-modal .focus.cm-btn,
.klaro .context-notice .focus.cm-btn,
.klaro .cookie-notice .focus.cm-btn {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(75, 91, 241, 0.25);
}
.btn.disabled, .klaro .cookie-modal .disabled.cm-btn,
.klaro .context-notice .disabled.cm-btn,
.klaro .cookie-notice .disabled.cm-btn, .btn:disabled, .klaro .cookie-modal .cm-btn:disabled,
.klaro .context-notice .cm-btn:disabled,
.klaro .cookie-notice .cm-btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled), .klaro .cookie-modal .cm-btn:not(:disabled):not(.disabled),
.klaro .context-notice .cm-btn:not(:disabled):not(.disabled),
.klaro .cookie-notice .cm-btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled, .klaro .cookie-modal a.disabled.cm-btn,
.klaro .context-notice a.disabled.cm-btn,
.klaro .cookie-notice a.disabled.cm-btn,
fieldset:disabled a.btn,
fieldset:disabled .klaro .cookie-modal a.cm-btn,
.klaro .cookie-modal fieldset:disabled a.cm-btn,
fieldset:disabled .klaro .context-notice a.cm-btn,
.klaro .context-notice fieldset:disabled a.cm-btn,
fieldset:disabled .klaro .cookie-notice a.cm-btn,
.klaro .cookie-notice fieldset:disabled a.cm-btn {
  pointer-events: none;
}

.btn-primary, .klaro .cookie-modal .cm-btn.cm-btn-accept-all, .klaro .cookie-modal .cm-btn.cm-btn-success:not(.cm-btn-info),
.klaro .context-notice .cm-btn.cm-btn-accept-all,
.klaro .context-notice .cm-btn.cm-btn-success:not(.cm-btn-info),
.klaro .cookie-notice .cm-btn.cm-btn-accept-all,
.klaro .cookie-notice .cm-btn.cm-btn-success:not(.cm-btn-info) {
  color: #fff;
  background-color: #4b5bf1;
  border-color: #4b5bf1;
}
.btn-primary:hover, .klaro .cookie-modal .cm-btn.cm-btn-accept-all:hover, .klaro .cookie-modal .cm-btn.cm-btn-success:hover:not(.cm-btn-info),
.klaro .context-notice .cm-btn.cm-btn-accept-all:hover,
.klaro .context-notice .cm-btn.cm-btn-success:hover:not(.cm-btn-info),
.klaro .cookie-notice .cm-btn.cm-btn-accept-all:hover,
.klaro .cookie-notice .cm-btn.cm-btn-success:hover:not(.cm-btn-info) {
  color: #fff;
  background-color: #283bee;
  border-color: #1c30ed;
}
.btn-primary:focus, .klaro .cookie-modal .cm-btn.cm-btn-accept-all:focus, .klaro .cookie-modal .cm-btn.cm-btn-success:focus:not(.cm-btn-info),
.klaro .context-notice .cm-btn.cm-btn-accept-all:focus,
.klaro .context-notice .cm-btn.cm-btn-success:focus:not(.cm-btn-info),
.klaro .cookie-notice .cm-btn.cm-btn-accept-all:focus,
.klaro .cookie-notice .cm-btn.cm-btn-success:focus:not(.cm-btn-info), .btn-primary.focus, .klaro .cookie-modal .focus.cm-btn.cm-btn-accept-all, .klaro .cookie-modal .focus.cm-btn.cm-btn-success:not(.cm-btn-info),
.klaro .context-notice .focus.cm-btn.cm-btn-accept-all,
.klaro .context-notice .focus.cm-btn.cm-btn-success:not(.cm-btn-info),
.klaro .cookie-notice .focus.cm-btn.cm-btn-accept-all,
.klaro .cookie-notice .focus.cm-btn.cm-btn-success:not(.cm-btn-info) {
  color: #fff;
  background-color: #283bee;
  border-color: #1c30ed;
  box-shadow: 0 0 0 0.2rem rgba(102, 116, 243, 0.5);
}
.btn-primary.disabled, .klaro .cookie-modal .disabled.cm-btn.cm-btn-accept-all, .klaro .cookie-modal .disabled.cm-btn.cm-btn-success:not(.cm-btn-info),
.klaro .context-notice .disabled.cm-btn.cm-btn-accept-all,
.klaro .context-notice .disabled.cm-btn.cm-btn-success:not(.cm-btn-info),
.klaro .cookie-notice .disabled.cm-btn.cm-btn-accept-all,
.klaro .cookie-notice .disabled.cm-btn.cm-btn-success:not(.cm-btn-info), .btn-primary:disabled, .klaro .cookie-modal .cm-btn.cm-btn-accept-all:disabled, .klaro .cookie-modal .cm-btn.cm-btn-success:disabled:not(.cm-btn-info),
.klaro .context-notice .cm-btn.cm-btn-accept-all:disabled,
.klaro .context-notice .cm-btn.cm-btn-success:disabled:not(.cm-btn-info),
.klaro .cookie-notice .cm-btn.cm-btn-accept-all:disabled,
.klaro .cookie-notice .cm-btn.cm-btn-success:disabled:not(.cm-btn-info) {
  color: #fff;
  background-color: #4b5bf1;
  border-color: #4b5bf1;
}
.btn-primary:not(:disabled):not(.disabled):active, .klaro .cookie-modal .cm-btn.cm-btn-accept-all:not(:disabled):not(.disabled):active, .klaro .cookie-modal .cm-btn.cm-btn-success:not(:disabled):not(.disabled):active:not(.cm-btn-info),
.klaro .context-notice .cm-btn.cm-btn-accept-all:not(:disabled):not(.disabled):active,
.klaro .context-notice .cm-btn.cm-btn-success:not(:disabled):not(.disabled):active:not(.cm-btn-info),
.klaro .cookie-notice .cm-btn.cm-btn-accept-all:not(:disabled):not(.disabled):active,
.klaro .cookie-notice .cm-btn.cm-btn-success:not(:disabled):not(.disabled):active:not(.cm-btn-info), .btn-primary:not(:disabled):not(.disabled).active, .klaro .cookie-modal .cm-btn.cm-btn-accept-all:not(:disabled):not(.disabled).active, .klaro .cookie-modal .cm-btn.cm-btn-success:not(:disabled):not(.disabled).active:not(.cm-btn-info),
.klaro .context-notice .cm-btn.cm-btn-accept-all:not(:disabled):not(.disabled).active,
.klaro .context-notice .cm-btn.cm-btn-success:not(:disabled):not(.disabled).active:not(.cm-btn-info),
.klaro .cookie-notice .cm-btn.cm-btn-accept-all:not(:disabled):not(.disabled).active,
.klaro .cookie-notice .cm-btn.cm-btn-success:not(:disabled):not(.disabled).active:not(.cm-btn-info), .show > .btn-primary.dropdown-toggle, .klaro .cookie-modal .show > .dropdown-toggle.cm-btn.cm-btn-accept-all, .klaro .cookie-modal .show > .dropdown-toggle.cm-btn.cm-btn-success:not(.cm-btn-info),
.klaro .context-notice .show > .dropdown-toggle.cm-btn.cm-btn-accept-all,
.klaro .context-notice .show > .dropdown-toggle.cm-btn.cm-btn-success:not(.cm-btn-info),
.klaro .cookie-notice .show > .dropdown-toggle.cm-btn.cm-btn-accept-all,
.klaro .cookie-notice .show > .dropdown-toggle.cm-btn.cm-btn-success:not(.cm-btn-info) {
  color: #fff;
  background-color: #1c30ed;
  border-color: #1227ea;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .klaro .cookie-modal .cm-btn.cm-btn-accept-all:not(:disabled):not(.disabled):active:focus, .klaro .cookie-modal .cm-btn.cm-btn-success:not(:disabled):not(.disabled):active:focus:not(.cm-btn-info),
.klaro .context-notice .cm-btn.cm-btn-accept-all:not(:disabled):not(.disabled):active:focus,
.klaro .context-notice .cm-btn.cm-btn-success:not(:disabled):not(.disabled):active:focus:not(.cm-btn-info),
.klaro .cookie-notice .cm-btn.cm-btn-accept-all:not(:disabled):not(.disabled):active:focus,
.klaro .cookie-notice .cm-btn.cm-btn-success:not(:disabled):not(.disabled):active:focus:not(.cm-btn-info), .btn-primary:not(:disabled):not(.disabled).active:focus, .klaro .cookie-modal .cm-btn.cm-btn-accept-all:not(:disabled):not(.disabled).active:focus, .klaro .cookie-modal .cm-btn.cm-btn-success:not(:disabled):not(.disabled).active:focus:not(.cm-btn-info),
.klaro .context-notice .cm-btn.cm-btn-accept-all:not(:disabled):not(.disabled).active:focus,
.klaro .context-notice .cm-btn.cm-btn-success:not(:disabled):not(.disabled).active:focus:not(.cm-btn-info),
.klaro .cookie-notice .cm-btn.cm-btn-accept-all:not(:disabled):not(.disabled).active:focus,
.klaro .cookie-notice .cm-btn.cm-btn-success:not(:disabled):not(.disabled).active:focus:not(.cm-btn-info), .show > .btn-primary.dropdown-toggle:focus, .klaro .cookie-modal .show > .dropdown-toggle.cm-btn.cm-btn-accept-all:focus, .klaro .cookie-modal .show > .dropdown-toggle.cm-btn.cm-btn-success:focus:not(.cm-btn-info),
.klaro .context-notice .show > .dropdown-toggle.cm-btn.cm-btn-accept-all:focus,
.klaro .context-notice .show > .dropdown-toggle.cm-btn.cm-btn-success:focus:not(.cm-btn-info),
.klaro .cookie-notice .show > .dropdown-toggle.cm-btn.cm-btn-accept-all:focus,
.klaro .cookie-notice .show > .dropdown-toggle.cm-btn.cm-btn-success:focus:not(.cm-btn-info) {
  box-shadow: 0 0 0 0.2rem rgba(102, 116, 243, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #00c7e3;
  border-color: #00c7e3;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #00a5bd;
  border-color: #009ab0;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #00a5bd;
  border-color: #009ab0;
  box-shadow: 0 0 0 0.2rem rgba(38, 207, 231, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #00c7e3;
  border-color: #00c7e3;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #009ab0;
  border-color: #008fa3;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 207, 231, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #f6a826;
  border-color: #f6a826;
}
.btn-warning:hover {
  color: #212529;
  background-color: #ec970a;
  border-color: #e08f09;
}
.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #ec970a;
  border-color: #e08f09;
  box-shadow: 0 0 0 0.2rem rgba(214, 148, 38, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #f6a826;
  border-color: #f6a826;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #e08f09;
  border-color: #d38709;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(214, 148, 38, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #e9ecef;
  border-color: #e9ecef;
}
.btn-light:hover {
  color: #212529;
  background-color: #d3d9df;
  border-color: #cbd3da;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #d3d9df;
  border-color: #cbd3da;
  box-shadow: 0 0 0 0.2rem rgba(203, 206, 209, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #e9ecef;
  border-color: #e9ecef;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #cbd3da;
  border-color: #c4ccd4;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(203, 206, 209, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-light-blue {
  color: #212529;
  background-color: #e8f9fc;
  border-color: #e8f9fc;
}
.btn-light-blue:hover {
  color: #212529;
  background-color: #c6f0f8;
  border-color: #bbedf6;
}
.btn-light-blue:focus, .btn-light-blue.focus {
  color: #212529;
  background-color: #c6f0f8;
  border-color: #bbedf6;
  box-shadow: 0 0 0 0.2rem rgba(202, 217, 220, 0.5);
}
.btn-light-blue.disabled, .btn-light-blue:disabled {
  color: #212529;
  background-color: #e8f9fc;
  border-color: #e8f9fc;
}
.btn-light-blue:not(:disabled):not(.disabled):active, .btn-light-blue:not(:disabled):not(.disabled).active, .show > .btn-light-blue.dropdown-toggle {
  color: #212529;
  background-color: #bbedf6;
  border-color: #b0eaf5;
}
.btn-light-blue:not(:disabled):not(.disabled):active:focus, .btn-light-blue:not(:disabled):not(.disabled).active:focus, .show > .btn-light-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(202, 217, 220, 0.5);
}

.btn-dark-blue {
  color: #fff;
  background-color: #0c214d;
  border-color: #0c214d;
}
.btn-dark-blue:hover {
  color: #fff;
  background-color: #07132c;
  border-color: #050e21;
}
.btn-dark-blue:focus, .btn-dark-blue.focus {
  color: #fff;
  background-color: #07132c;
  border-color: #050e21;
  box-shadow: 0 0 0 0.2rem rgba(48, 66, 104, 0.5);
}
.btn-dark-blue.disabled, .btn-dark-blue:disabled {
  color: #fff;
  background-color: #0c214d;
  border-color: #0c214d;
}
.btn-dark-blue:not(:disabled):not(.disabled):active, .btn-dark-blue:not(:disabled):not(.disabled).active, .show > .btn-dark-blue.dropdown-toggle {
  color: #fff;
  background-color: #050e21;
  border-color: #030916;
}
.btn-dark-blue:not(:disabled):not(.disabled):active:focus, .btn-dark-blue:not(:disabled):not(.disabled).active:focus, .show > .btn-dark-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(48, 66, 104, 0.5);
}

.btn-outline-primary, .klaro .cookie-modal .cm-btn.cm-btn-info, .klaro .cookie-modal .cm-btn.cm-btn-danger, .klaro .cookie-modal .cm-btn.cm-btn-accept,
.klaro .context-notice .cm-btn.cm-btn-info,
.klaro .context-notice .cm-btn.cm-btn-danger,
.klaro .context-notice .cm-btn.cm-btn-accept,
.klaro .cookie-notice .cm-btn.cm-btn-info,
.klaro .cookie-notice .cm-btn.cm-btn-danger,
.klaro .cookie-notice .cm-btn.cm-btn-accept {
  color: #4b5bf1;
  border-color: #4b5bf1;
}
.btn-outline-primary:hover, .klaro .cookie-modal .cm-btn.cm-btn-info:hover, .klaro .cookie-modal .cm-btn.cm-btn-danger:hover, .klaro .cookie-modal .cm-btn.cm-btn-accept:hover,
.klaro .context-notice .cm-btn.cm-btn-info:hover,
.klaro .context-notice .cm-btn.cm-btn-danger:hover,
.klaro .context-notice .cm-btn.cm-btn-accept:hover,
.klaro .cookie-notice .cm-btn.cm-btn-info:hover,
.klaro .cookie-notice .cm-btn.cm-btn-danger:hover,
.klaro .cookie-notice .cm-btn.cm-btn-accept:hover {
  color: #fff;
  background-color: #4b5bf1;
  border-color: #4b5bf1;
}
.btn-outline-primary:focus, .klaro .cookie-modal .cm-btn.cm-btn-info:focus, .klaro .cookie-modal .cm-btn.cm-btn-danger:focus, .klaro .cookie-modal .cm-btn.cm-btn-accept:focus,
.klaro .context-notice .cm-btn.cm-btn-info:focus,
.klaro .context-notice .cm-btn.cm-btn-danger:focus,
.klaro .context-notice .cm-btn.cm-btn-accept:focus,
.klaro .cookie-notice .cm-btn.cm-btn-info:focus,
.klaro .cookie-notice .cm-btn.cm-btn-danger:focus,
.klaro .cookie-notice .cm-btn.cm-btn-accept:focus, .btn-outline-primary.focus, .klaro .cookie-modal .focus.cm-btn.cm-btn-info, .klaro .cookie-modal .focus.cm-btn.cm-btn-danger, .klaro .cookie-modal .focus.cm-btn.cm-btn-accept,
.klaro .context-notice .focus.cm-btn.cm-btn-info,
.klaro .context-notice .focus.cm-btn.cm-btn-danger,
.klaro .context-notice .focus.cm-btn.cm-btn-accept,
.klaro .cookie-notice .focus.cm-btn.cm-btn-info,
.klaro .cookie-notice .focus.cm-btn.cm-btn-danger,
.klaro .cookie-notice .focus.cm-btn.cm-btn-accept {
  box-shadow: 0 0 0 0.2rem rgba(75, 91, 241, 0.5);
}
.btn-outline-primary.disabled, .klaro .cookie-modal .disabled.cm-btn.cm-btn-info, .klaro .cookie-modal .disabled.cm-btn.cm-btn-danger, .klaro .cookie-modal .disabled.cm-btn.cm-btn-accept,
.klaro .context-notice .disabled.cm-btn.cm-btn-info,
.klaro .context-notice .disabled.cm-btn.cm-btn-danger,
.klaro .context-notice .disabled.cm-btn.cm-btn-accept,
.klaro .cookie-notice .disabled.cm-btn.cm-btn-info,
.klaro .cookie-notice .disabled.cm-btn.cm-btn-danger,
.klaro .cookie-notice .disabled.cm-btn.cm-btn-accept, .btn-outline-primary:disabled, .klaro .cookie-modal .cm-btn.cm-btn-info:disabled, .klaro .cookie-modal .cm-btn.cm-btn-danger:disabled, .klaro .cookie-modal .cm-btn.cm-btn-accept:disabled,
.klaro .context-notice .cm-btn.cm-btn-info:disabled,
.klaro .context-notice .cm-btn.cm-btn-danger:disabled,
.klaro .context-notice .cm-btn.cm-btn-accept:disabled,
.klaro .cookie-notice .cm-btn.cm-btn-info:disabled,
.klaro .cookie-notice .cm-btn.cm-btn-danger:disabled,
.klaro .cookie-notice .cm-btn.cm-btn-accept:disabled {
  color: #4b5bf1;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .klaro .cookie-modal .cm-btn.cm-btn-info:not(:disabled):not(.disabled):active, .klaro .cookie-modal .cm-btn.cm-btn-danger:not(:disabled):not(.disabled):active, .klaro .cookie-modal .cm-btn.cm-btn-accept:not(:disabled):not(.disabled):active,
.klaro .context-notice .cm-btn.cm-btn-info:not(:disabled):not(.disabled):active,
.klaro .context-notice .cm-btn.cm-btn-danger:not(:disabled):not(.disabled):active,
.klaro .context-notice .cm-btn.cm-btn-accept:not(:disabled):not(.disabled):active,
.klaro .cookie-notice .cm-btn.cm-btn-info:not(:disabled):not(.disabled):active,
.klaro .cookie-notice .cm-btn.cm-btn-danger:not(:disabled):not(.disabled):active,
.klaro .cookie-notice .cm-btn.cm-btn-accept:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .klaro .cookie-modal .cm-btn.cm-btn-info:not(:disabled):not(.disabled).active, .klaro .cookie-modal .cm-btn.cm-btn-danger:not(:disabled):not(.disabled).active, .klaro .cookie-modal .cm-btn.cm-btn-accept:not(:disabled):not(.disabled).active,
.klaro .context-notice .cm-btn.cm-btn-info:not(:disabled):not(.disabled).active,
.klaro .context-notice .cm-btn.cm-btn-danger:not(:disabled):not(.disabled).active,
.klaro .context-notice .cm-btn.cm-btn-accept:not(:disabled):not(.disabled).active,
.klaro .cookie-notice .cm-btn.cm-btn-info:not(:disabled):not(.disabled).active,
.klaro .cookie-notice .cm-btn.cm-btn-danger:not(:disabled):not(.disabled).active,
.klaro .cookie-notice .cm-btn.cm-btn-accept:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle, .klaro .cookie-modal .show > .dropdown-toggle.cm-btn.cm-btn-info, .klaro .cookie-modal .show > .dropdown-toggle.cm-btn.cm-btn-danger, .klaro .cookie-modal .show > .dropdown-toggle.cm-btn.cm-btn-accept,
.klaro .context-notice .show > .dropdown-toggle.cm-btn.cm-btn-info,
.klaro .context-notice .show > .dropdown-toggle.cm-btn.cm-btn-danger,
.klaro .context-notice .show > .dropdown-toggle.cm-btn.cm-btn-accept,
.klaro .cookie-notice .show > .dropdown-toggle.cm-btn.cm-btn-info,
.klaro .cookie-notice .show > .dropdown-toggle.cm-btn.cm-btn-danger,
.klaro .cookie-notice .show > .dropdown-toggle.cm-btn.cm-btn-accept {
  color: #fff;
  background-color: #4b5bf1;
  border-color: #4b5bf1;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .klaro .cookie-modal .cm-btn.cm-btn-info:not(:disabled):not(.disabled):active:focus, .klaro .cookie-modal .cm-btn.cm-btn-danger:not(:disabled):not(.disabled):active:focus, .klaro .cookie-modal .cm-btn.cm-btn-accept:not(:disabled):not(.disabled):active:focus,
.klaro .context-notice .cm-btn.cm-btn-info:not(:disabled):not(.disabled):active:focus,
.klaro .context-notice .cm-btn.cm-btn-danger:not(:disabled):not(.disabled):active:focus,
.klaro .context-notice .cm-btn.cm-btn-accept:not(:disabled):not(.disabled):active:focus,
.klaro .cookie-notice .cm-btn.cm-btn-info:not(:disabled):not(.disabled):active:focus,
.klaro .cookie-notice .cm-btn.cm-btn-danger:not(:disabled):not(.disabled):active:focus,
.klaro .cookie-notice .cm-btn.cm-btn-accept:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .klaro .cookie-modal .cm-btn.cm-btn-info:not(:disabled):not(.disabled).active:focus, .klaro .cookie-modal .cm-btn.cm-btn-danger:not(:disabled):not(.disabled).active:focus, .klaro .cookie-modal .cm-btn.cm-btn-accept:not(:disabled):not(.disabled).active:focus,
.klaro .context-notice .cm-btn.cm-btn-info:not(:disabled):not(.disabled).active:focus,
.klaro .context-notice .cm-btn.cm-btn-danger:not(:disabled):not(.disabled).active:focus,
.klaro .context-notice .cm-btn.cm-btn-accept:not(:disabled):not(.disabled).active:focus,
.klaro .cookie-notice .cm-btn.cm-btn-info:not(:disabled):not(.disabled).active:focus,
.klaro .cookie-notice .cm-btn.cm-btn-danger:not(:disabled):not(.disabled).active:focus,
.klaro .cookie-notice .cm-btn.cm-btn-accept:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus, .klaro .cookie-modal .show > .dropdown-toggle.cm-btn.cm-btn-info:focus, .klaro .cookie-modal .show > .dropdown-toggle.cm-btn.cm-btn-danger:focus, .klaro .cookie-modal .show > .dropdown-toggle.cm-btn.cm-btn-accept:focus,
.klaro .context-notice .show > .dropdown-toggle.cm-btn.cm-btn-info:focus,
.klaro .context-notice .show > .dropdown-toggle.cm-btn.cm-btn-danger:focus,
.klaro .context-notice .show > .dropdown-toggle.cm-btn.cm-btn-accept:focus,
.klaro .cookie-notice .show > .dropdown-toggle.cm-btn.cm-btn-info:focus,
.klaro .cookie-notice .show > .dropdown-toggle.cm-btn.cm-btn-danger:focus,
.klaro .cookie-notice .show > .dropdown-toggle.cm-btn.cm-btn-accept:focus {
  box-shadow: 0 0 0 0.2rem rgba(75, 91, 241, 0.5);
}

.btn-outline-secondary {
  color: #00c7e3;
  border-color: #00c7e3;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #00c7e3;
  border-color: #00c7e3;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 199, 227, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #00c7e3;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #00c7e3;
  border-color: #00c7e3;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 199, 227, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #f6a826;
  border-color: #f6a826;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #f6a826;
  border-color: #f6a826;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(246, 168, 38, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #f6a826;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #f6a826;
  border-color: #f6a826;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(246, 168, 38, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #e9ecef;
  border-color: #e9ecef;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #e9ecef;
  border-color: #e9ecef;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(233, 236, 239, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #e9ecef;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #e9ecef;
  border-color: #e9ecef;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(233, 236, 239, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-light-blue {
  color: #e8f9fc;
  border-color: #e8f9fc;
}
.btn-outline-light-blue:hover {
  color: #212529;
  background-color: #e8f9fc;
  border-color: #e8f9fc;
}
.btn-outline-light-blue:focus, .btn-outline-light-blue.focus {
  box-shadow: 0 0 0 0.2rem rgba(232, 249, 252, 0.5);
}
.btn-outline-light-blue.disabled, .btn-outline-light-blue:disabled {
  color: #e8f9fc;
  background-color: transparent;
}
.btn-outline-light-blue:not(:disabled):not(.disabled):active, .btn-outline-light-blue:not(:disabled):not(.disabled).active, .show > .btn-outline-light-blue.dropdown-toggle {
  color: #212529;
  background-color: #e8f9fc;
  border-color: #e8f9fc;
}
.btn-outline-light-blue:not(:disabled):not(.disabled):active:focus, .btn-outline-light-blue:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(232, 249, 252, 0.5);
}

.btn-outline-dark-blue {
  color: #0c214d;
  border-color: #0c214d;
}
.btn-outline-dark-blue:hover {
  color: #fff;
  background-color: #0c214d;
  border-color: #0c214d;
}
.btn-outline-dark-blue:focus, .btn-outline-dark-blue.focus {
  box-shadow: 0 0 0 0.2rem rgba(12, 33, 77, 0.5);
}
.btn-outline-dark-blue.disabled, .btn-outline-dark-blue:disabled {
  color: #0c214d;
  background-color: transparent;
}
.btn-outline-dark-blue:not(:disabled):not(.disabled):active, .btn-outline-dark-blue:not(:disabled):not(.disabled).active, .show > .btn-outline-dark-blue.dropdown-toggle {
  color: #fff;
  background-color: #0c214d;
  border-color: #0c214d;
}
.btn-outline-dark-blue:not(:disabled):not(.disabled):active:focus, .btn-outline-dark-blue:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(12, 33, 77, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #4b5bf1;
  text-decoration: none;
}
.btn-link:hover {
  color: #1125de;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #868e96;
  pointer-events: none;
}

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 3rem;
}

.btn-sm, .klaro .cookie-modal .cm-btn,
.klaro .context-notice .cm-btn,
.klaro .cookie-notice .cm-btn {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #868e96;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #868e96;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #4b5bf1;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #272f7d;
  background-color: #dbdefc;
  border-color: #cdd1fb;
}
.alert-primary hr {
  border-top-color: #b5bbf9;
}
.alert-primary .alert-link {
  color: #1b2056;
}

.alert-secondary {
  color: #006776;
  background-color: #ccf4f9;
  border-color: #b8eff7;
}
.alert-secondary hr {
  border-top-color: #a1eaf4;
}
.alert-secondary .alert-link {
  color: #003a43;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-success hr {
  border-top-color: #b1dfbb;
}
.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #805714;
  background-color: #fdeed4;
  border-color: #fce7c2;
}
.alert-warning hr {
  border-top-color: #fbddaa;
}
.alert-warning .alert-link {
  color: #54390d;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-danger hr {
  border-top-color: #f1b0b7;
}
.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #797b7c;
  background-color: #fbfbfc;
  border-color: #f9fafb;
}
.alert-light hr {
  border-top-color: #eaedf1;
}
.alert-light .alert-link {
  color: #606162;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}

.alert-light-blue {
  color: #798183;
  background-color: #fafefe;
  border-color: #f9fdfe;
}
.alert-light-blue hr {
  border-top-color: #e3f6fa;
}
.alert-light-blue .alert-link {
  color: #616768;
}

.alert-dark-blue {
  color: #061128;
  background-color: #ced3db;
  border-color: #bbc1cd;
}
.alert-dark-blue hr {
  border-top-color: #acb4c2;
}
.alert-dark-blue .alert-link {
  color: black;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #4b5bf1 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #1c30ed !important;
}

.bg-secondary {
  background-color: #00c7e3 !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #009ab0 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #f6a826 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #e08f09 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #e9ecef !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #cbd3da !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-light-blue {
  background-color: #e8f9fc !important;
}

a.bg-light-blue:hover, a.bg-light-blue:focus,
button.bg-light-blue:hover,
button.bg-light-blue:focus {
  background-color: #bbedf6 !important;
}

.bg-dark-blue {
  background-color: #0c214d !important;
}

a.bg-dark-blue:hover, a.bg-dark-blue:focus,
button.bg-dark-blue:hover,
button.bg-dark-blue:focus {
  background-color: #050e21 !important;
}

.bg-white, .post-inner {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #4b5bf1 !important;
}

.border-secondary {
  border-color: #00c7e3 !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #f6a826 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #e9ecef !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-light-blue {
  border-color: #e8f9fc !important;
}

.border-dark-blue {
  border-color: #0c214d !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom, .post-inner {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm, .post-inner {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3, .post-inner .entry-content,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4, .post-inner .entry-header,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4,
.entry-footer,
.post-inner .entry-content,
.post-inner .entry-header,
aside.sidebar-primary .entry-title,
aside.sidebar-primary .post-info,
aside.sidebar-primary p {
  padding-right: 1.5rem !important;
}

.pb-4, aside.sidebar-primary p:last-child,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4,
.entry-footer,
.post-inner .entry-content,
.post-inner .entry-header,
aside.sidebar-primary .entry-title,
aside.sidebar-primary .post-info,
aside.sidebar-primary p {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #4b5bf1 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #1125de !important;
}

.text-secondary {
  color: #00c7e3 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #008497 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #f6a826 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #c78008 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #e9ecef !important;
}

a.text-light:hover, a.text-light:focus {
  color: #bdc6cf !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-light-blue {
  color: #e8f9fc !important;
}

a.text-light-blue:hover, a.text-light-blue:focus {
  color: #a4e7f3 !important;
}

.text-dark-blue {
  color: #0c214d !important;
}

a.text-dark-blue:hover, a.text-dark-blue:focus {
  color: #02050b !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #868e96 !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

/* # Table of Contents
- Defaults
  - Typographical Elements
  - Headings
  - Screen Reader Text
- Structure and Layout
  - Site Containers
  - Column Widths and Positions
- Common Classes
  - Genesis
  - Search Form
  - Titles
  - WordPress
- Widgets
  - Featured Content
- Plugins
  - Genesis eNews Extended
  - Jetpack
- Skip Links
- Site Header
  - Title Area
  - Widget Area
- Site Navigation
  - Accessible Menu
  - Site Header Navigation
  - Primary and Secondary Navigation
- Content Area
  - Entries
  - Entry Meta
  - Pagination
  - Comments
- Sidebars
- Site Footer
- Media Queries
  - Max-width: 1280px
  - Max-width: 1023px
  - Max-width: 880px
- Print Styles
*/
/* # Defaults
---------------------------------------------------------------------------------------------------- */
/* ## Typographical Elements
--------------------------------------------- */
a,
button,
input:focus,
input[type=button],
input[type=reset],
input[type=submit],
textarea:focus,
.button,
.gallery img {
  transition: all 0.1s ease-in-out;
}

hr {
  margin: 1em 0;
  clear: both;
  border-collapse: collapse;
  border: 0;
  border-top: 1px solid #ddd;
}

b,
strong {
  font-weight: 700;
}

blockquote,
cite,
em,
i {
  font-style: italic;
}

blockquote {
  margin: 40px;
}

blockquote::before {
  content: "“";
  display: block;
  position: relative;
  top: -10px;
  left: -20px;
  height: 0;
  font-size: 30px;
}

/* cesures */
.hyphen {
  hyphens: auto;
  hyphens: auto;
  hyphens: auto;
  hyphens: auto;
  hyphens: auto;
}

/* ## Headings
--------------------------------------------- */
/* ## Screen Reader Text
--------------------------------------------- */
.screen-reader-text,
.screen-reader-text span,
.screen-reader-shortcut {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  word-wrap: normal !important;
}

.screen-reader-text:focus,
.screen-reader-shortcut:focus,
.genesis-nav-menu .search input[type=submit]:focus {
  display: block;
  z-index: 100000;
  width: auto;
  height: auto;
  padding: 15px 23px 14px;
  clip: auto !important;
  background: #fff;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  color: #333;
  font-size: 1em;
  font-weight: bold;
  /* Above WP toolbar. */
  text-decoration: none;
}

.more-link {
  display: block;
  position: relative;
  font-weight: 600;
}
.more-link:hover {
  color: #00c7e3;
  text-decoration: none;
}

/* # Structure and Layout
---------------------------------------------------------------------------------------------------- */
/* ## Site Containers
--------------------------------------------- */
.site-inner {
  clear: both;
  overflow-x: auto;
}

/* ## Column Widths and Positions
--------------------------------------------- */
/* ### Wrapping div for .content and .sidebar-primary */
/* # Common Classes
---------------------------------------------------------------------------------------------------- */
/* ## Genesis
--------------------------------------------- */
.archive-description,
.author-box {
  margin-bottom: 60px;
}

.archive-description p:last-child,
.author-box p:last-child {
  margin-bottom: 0;
}

/* ## Search Form
--------------------------------------------- */
.search-form {
  overflow: hidden;
}

.site-header .search-form {
  margin-top: 12px;
  float: right;
}

.entry-content .search-form,
.site-header .search-form {
  width: 50%;
}

/* ## Titles
--------------------------------------------- */
.widget-title {
  margin-bottom: 1.25rem;
}

/* ## WordPress
--------------------------------------------- */
a.aligncenter img {
  display: block;
  margin: 0 auto;
}

a.alignnone {
  display: inline-block;
}

.alignleft {
  float: left;
  text-align: left;
}

.alignright {
  float: right;
  text-align: right;
}

a.alignleft,
a.alignnone,
a.alignright {
  max-width: 100%;
}

img.centered,
.aligncenter {
  display: block;
  margin: 0 auto 24px;
}

img.alignnone,
.alignnone {
  margin-bottom: 12px;
}

.wp-caption img {
  margin-bottom: 12px;
}

a.alignleft,
img.alignleft,
.wp-caption.alignleft {
  margin: 0 24px 24px 0;
}

a.alignright,
img.alignright,
.wp-caption.alignright {
  margin: 0 0 24px 24px;
}

.wp-caption-text {
  text-align: center;
}

.entry-content p.wp-caption-text {
  margin-bottom: 0;
}

.entry-content .wp-audio-shortcode,
.entry-content .wp-playlist,
.entry-content .wp-video {
  margin: 0 0 28px;
}

/* # Widgets
---------------------------------------------------------------------------------------------------- */
.widget {
  word-wrap: break-word;
}

.widget ol > li {
  padding-left: 20px;
  list-style-position: inside;
  list-style-type: decimal;
  text-indent: -20px;
}

.widget li li {
  margin: 0 0 0 30px;
  padding: 0;
  border: 0;
}

.widget_calendar table {
  width: 100%;
}

.widget_calendar td,
.widget_calendar th {
  text-align: center;
}

/* ## Featured Content
--------------------------------------------- */
.featured-content .entry h2 {
  font-size: 1rem;
}

.featured-content .entry:last-child {
  margin-bottom: 0;
}

.featured-content .entry-title {
  font-size: inherit;
  font-weight: 400;
}

/* ## Jetpack
--------------------------------------------- */
#wpstats {
  display: none;
}

/* # Skip Links
---------------------------------------------------------------------------------------------------- */
.genesis-skip-link {
  margin: 0;
}

.genesis-skip-link li {
  width: 0;
  height: 0;
  list-style: none;
}

/* Display outline on focus */
:focus {
  outline: 0 !important;
}

/* # Site Header
---------------------------------------------------------------------------------------------------- */
/* ## Title Area
--------------------------------------------- */
.title-area {
  float: left;
}

.site-title {
  font-weight: 700;
  line-height: 1.2;
}

.site-title a,
.site-title a:hover,
.site-title a:focus {
  color: #333;
}

.header-image .site-title > a {
  width: 100%;
  min-height: 60px;
  float: left;
  background: url(images/logo.png) no-repeat left;
}

.site-description {
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 1.5;
}

.site-description,
.site-title {
  margin-bottom: 0;
}

.header-image .site-description,
.header-image .site-title a {
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
}

/* ## Widget Area
--------------------------------------------- */
.site-header .widget-area {
  float: right;
  text-align: right;
}

/* # Site Navigation
---------------------------------------------------------------------------------------------------- */
/* ## Accessible Menu
--------------------------------------------- */
.menu .menu-item:focus {
  position: static;
}

.menu .menu-item > a:focus + ul.sub-menu,
.menu .menu-item.sfHover > ul.sub-menu {
  left: auto;
  opacity: 1;
}

/* ## Site Header Navigation
--------------------------------------------- */
.site-header .genesis-nav-menu li li {
  margin-left: 0;
}

/* ## Primary and Secondary Navigation
--------------------------------------------- */
/* # Content Area
---------------------------------------------------------------------------------------------------- */
/* ## Entries
--------------------------------------------- */
/* ## Entry Meta
--------------------------------------------- */
/* ## Pagination
--------------------------------------------- */
.pagination {
  width: 100%;
  margin: 3rem 0;
  clear: both;
  text-align: center;
}
.pagination ul {
  padding: 0;
}

.pagination li {
  display: inline;
}

.pagination li a {
  display: inline-block;
  padding-right: 10px;
  text-decoration: none;
  cursor: pointer;
}

.pagination .active a {
  color: #333;
}

/* ## Comments
--------------------------------------------- */
.comment-respond,
.entry-comments,
.entry-pings {
  margin-bottom: 60px;
}

.comment-header {
  font-size: 16px;
  font-size: 1.6rem;
}

.comment-content {
  clear: both;
  word-wrap: break-word;
}

.comment-list li {
  padding: 32px 0 0 32px;
}

.comment-list li.depth-1 {
  padding-left: 0;
}

.comment-respond input[type=email],
.comment-respond input[type=text],
.comment-respond input[type=url] {
  width: 50%;
}

.comment-respond label {
  display: block;
  margin-right: 12px;
}

.entry-comments .comment-author {
  margin-bottom: 0;
}

.entry-pings .reply {
  display: none;
}

/* # Sidebars
---------------------------------------------------------------------------------------------------- */
/* # Site Footer
---------------------------------------------------------------------------------------------------- */
.site-footer p {
  margin-bottom: 0;
}

/* # Media Queries
---------------------------------------------------------------------------------------------------- */
@media only screen and (max-width: 1020px) {
  .site-header .wrap {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .header-image .site-title > a {
    background-position: center top;
  }

  .genesis-nav-menu li,
.site-header ul.genesis-nav-menu,
.site-header .search-form {
    float: none;
  }

  .genesis-nav-menu a,
.genesis-nav-menu > .first > a,
.genesis-nav-menu > .last > a {
    padding: 20px 16px;
  }

  .site-header .search-form {
    margin: 16px auto;
  }
}
/* # Print Styles
---------------------------------------------------------------------------------------------------- */
@media print {
  *,
*::before,
*::after {
    background: transparent !important;
    box-shadow: none !important;
    color: #000 !important;
    text-shadow: none !important;
  }

  a,
a:visited {
    text-decoration: underline;
  }

  a[href]::after {
    content: " (" attr(href) ")";
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  a[href^="javascript:"]::after,
a[href^="#"]::after,
.site-title > a::after {
    content: "";
  }

  thead {
    display: table-header-group;
  }

  img,
tr {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  @page {
    margin: 2cm 0.5cm;
  }
  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  blockquote,
pre {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  .content,
.content-sidebar {
    width: 100%;
  }

  button,
input,
select,
textarea,
.breadcrumb,
.comment-edit-link,
.comment-form,
.comment-list .reply a,
.comment-reply-title,
.edit-link,
.entry-comments-link,
.entry-footer,
.genesis-box,
.header-widget-area,
.hidden-print,
.home-top,
.nav-primary,
.nav-secondary,
.post-edit-link,
.sidebar {
    display: none !important;
  }

  .title-area {
    width: 100%;
    text-align: center;
  }

  .site-title > a {
    margin: 0;
    text-decoration: none;
    text-indent: 0;
  }

  .site-inner {
    position: relative;
    top: -100px;
    padding-top: 0;
  }

  .author-box {
    margin-bottom: 0;
  }

  h1,
h2,
h3,
h4,
.sidebar .entry-title a,
h5,
h6 {
    orphans: 3;
    page-break-after: avoid;
    page-break-inside: avoid;
    widows: 3;
  }

  img {
    page-break-after: avoid;
    page-break-inside: avoid;
  }

  blockquote,
pre,
table {
    page-break-inside: avoid;
  }

  dl,
ol,
ul {
    page-break-before: avoid;
  }
}
@font-face {
  font-family: "FontAwesome";
  src: url("../fonts/FontAwesome.eot");
  src: url("../fonts/FontAwesome.eot?#iefix") format("embedded-opentype"), url("../fonts/FontAwesome.woff2") format("woff2"), url("../fonts/FontAwesome.woff") format("woff"), url("../fonts/FontAwesome.ttf") format("ttf"), url("../fonts/FontAwesome.svg#FontAwesome") format("svg");
  font-style: normal;
  font-weight: normal;
}
.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fa-lg {
  font-size: 1.33333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-fw {
  width: 1.28571429em;
  text-align: center;
}

.fa-ul {
  padding-left: 0;
  margin-left: 2.14285714em;
  list-style-type: none;
}

.fa-ul > li {
  position: relative;
}

.fa-li {
  position: absolute;
  left: -2.14285714em;
  width: 2.14285714em;
  top: 0.14285714em;
  text-align: center;
}

.fa-li.fa-lg {
  left: -1.85714286em;
}

.fa-border {
  padding: 0.2em 0.25em 0.15em;
  border: solid 0.08em #eee;
  border-radius: 0.1em;
}

.fa-pull-left {
  float: left;
}

.fa-pull-right {
  float: right;
}

.fa.fa-pull-left {
  margin-right: 0.3em;
}

.fa.fa-pull-right {
  margin-left: 0.3em;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.fa.pull-left {
  margin-right: 0.3em;
}

.fa.pull-right {
  margin-left: 0.3em;
}

.fa-stack {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: middle;
}

.fa-stack-1x,
.fa-stack-2x {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-linkedin:before {
  content: "\f0e1";
}

.fa-twitter:before {
  content: "\f099";
}

.fa-facebook-f:before,
.fa-facebook:before {
  content: "\f09a";
}

.fa-pinterest:before {
  content: "\f0d2";
}

.fa-youtube:before {
  content: "\f167";
}

.fa-angle-left:before {
  content: "\f104";
}

.fa-angle-right:before {
  content: "\f105";
}

.fa-angle-double-right:before {
  content: "\f101";
}

.fa-bars:after {
  content: "\f0c9";
  color: #26B6BF;
  zoom: 1.5;
  margin-left: 10px;
}

.fa-lock:before {
  content: "\f023";
}

.fa-google-plus:before {
  content: "\f0d5";
}

.fa-circle-o-notch:before {
  content: "\f1ce";
}

.fa-long-arrow-right:before {
  content: "\f178";
}

@font-face {
  font-family: "Avenir";
  /* Legacy iOS */
  font-weight: 400;
  /* IE9 Compat Modes */
  src: url("../fonts/AvenirNextLTPro-Regular.woff2") format("woff2"), url("../fonts/AvenirNextLTPro-Regular.woff") format("woff");
}
@font-face {
  font-family: "Avenir";
  /* Legacy iOS */
  font-weight: 500;
  src: url("../fonts/AvenirNextLTPro-Medium.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/AvenirNextLTPro-Medium.woff2") format("woff2"), url("../fonts/AvenirNextLTPro-Medium.woff") format("woff");
}
@font-face {
  font-family: "Avenir";
  /* Legacy iOS */
  font-weight: 600;
  /* IE9 Compat Modes */
  src: url("../fonts/AvenirNextLTPro-Demi.woff2") format("woff2"), url("../fonts/AvenirNextLTPro-Demi.woff") format("woff");
}
@font-face {
  font-family: "Avenir";
  /* Legacy iOS */
  font-weight: 700;
  /* IE9 Compat Modes */
  src: url("../fonts/AvenirNextLTPro-Bold.woff2") format("woff2"), url("../fonts/AvenirNextLTPro-Bold.woff") format("woff");
}
@font-face {
  font-family: "Avenir";
  /* Legacy iOS */
  font-weight: 700;
  /* IE9 Compat Modes */
  src: url("../fonts/AvenirNextLTPro-Bold.woff2") format("woff2"), url("../fonts/AvenirNextLTPro-Bold.woff");
}
@font-face {
  font-family: "Univia";
  /* Legacy iOS */
  font-weight: 500;
  src: url("../fonts/UniviaProRegular.woff2") format("woff2"), url("../fonts/UniviaProRegular.woff") format("woff");
}
body {
  font-family: "Avenir";
}

h1,
h2,
h3,
h4,
.sidebar .entry-title a,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: #495057;
  font-weight: 500;
}

h1,
h2,
h3,
h4,
.sidebar .entry-title a,
.h1,
.h2,
.h3,
.h4 {
  font-family: "Univia";
}

h6,
.h6 {
  letter-spacing: 1px;
}

h5,
.h5,
h6,
.h6 {
  color: #4b5bf1;
}

h5,
.h5,
h6,
.h6 {
  font-family: "Avenir";
}

.display-4 {
  font-weight: 500;
}

.menu-item {
  font-family: "Univia";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  overflow-x: hidden;
}

img {
  max-width: 100%;
  max-width: 100%;
  height: auto;
}

label {
  display: block;
}

a {
  text-decoration-skip-ink: auto;
}
a:hover {
  color: #00c7e3;
  text-decoration: none;
}

.borders {
  border: 1px solid rgba(86, 61, 124, 0.15);
}

img.rounded {
  border-radius: 0.7rem !important;
}

.underlined {
  position: relative;
}
.underlined::after {
  content: "";
  display: block;
  position: absolute;
  top: 90%;
  left: 0;
  width: 100%;
  height: 3px;
  transition: all 0.15s ease-out;
  background: linear-gradient(to right, #4b5bf1, #00c7e3);
}

a.underlined {
  color: #343a40;
}
a.underlined::after {
  top: 110%;
}
a.underlined:hover {
  color: #4b5bf1;
  text-decoration: none;
}
a.underlined:hover::after {
  top: 90%;
}

.big {
  display: table;
  margin-right: auto;
  margin-left: auto;
  font-size: 1.5rem;
  font-weight: 600;
}

/* Change Autocomplete styles in Chrome */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: 0 0 0 1000px #00a8bf inset;
}

.no-bullets li, .footer-widgets ul li {
  list-style-type: none;
}

.reverse-image {
  transform: scaleX(-1);
  transform: scaleX(-1);
  transform: scaleX(-1);
  transform: scaleX(-1);
  filter: "FlipH";
  filter: fliph;
}

.abs-center-v {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.abs-center-h {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

table,
th,
td {
  border: 1px solid #4b5bf1;
}

.btn, .klaro .cookie-modal .cm-btn,
.klaro .context-notice .cm-btn,
.klaro .cookie-notice .cm-btn {
  box-shadow: none;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.btn.loading:hover, .klaro .cookie-modal .loading.cm-btn:hover,
.klaro .context-notice .loading.cm-btn:hover,
.klaro .cookie-notice .loading.cm-btn:hover {
  color: transparent;
}

.btn-secondary:hover {
  color: white;
}

.wpcf7-submit.loading {
  transition: background-position 0s;
  background-image: url(../images/rolling.svg);
  background-repeat: no-repeat;
  background-position: center center;
  color: transparent;
}

.btn-lg {
  padding: 0.8rem 2.5rem;
}

.btn-primary:focus, .klaro .cookie-modal .cm-btn.cm-btn-accept-all:focus, .klaro .cookie-modal .cm-btn.cm-btn-success:focus:not(.cm-btn-info),
.klaro .context-notice .cm-btn.cm-btn-accept-all:focus,
.klaro .context-notice .cm-btn.cm-btn-success:focus:not(.cm-btn-info),
.klaro .cookie-notice .cm-btn.cm-btn-accept-all:focus,
.klaro .cookie-notice .cm-btn.cm-btn-success:focus:not(.cm-btn-info), .btn-primary.focus, .klaro .cookie-modal .focus.cm-btn.cm-btn-accept-all, .klaro .cookie-modal .focus.cm-btn.cm-btn-success:not(.cm-btn-info),
.klaro .context-notice .focus.cm-btn.cm-btn-accept-all,
.klaro .context-notice .focus.cm-btn.cm-btn-success:not(.cm-btn-info),
.klaro .cookie-notice .focus.cm-btn.cm-btn-accept-all,
.klaro .cookie-notice .focus.cm-btn.cm-btn-success:not(.cm-btn-info),
.btn-secondary:focus,
.btn-secondary.focus {
  box-shadow: none;
}
.btn-primary:hover, .klaro .cookie-modal .cm-btn.cm-btn-accept-all:hover, .klaro .cookie-modal .cm-btn.cm-btn-success:hover:not(.cm-btn-info),
.klaro .context-notice .cm-btn.cm-btn-accept-all:hover,
.klaro .context-notice .cm-btn.cm-btn-success:hover:not(.cm-btn-info),
.klaro .cookie-notice .cm-btn.cm-btn-accept-all:hover,
.klaro .cookie-notice .cm-btn.cm-btn-success:hover:not(.cm-btn-info),
.btn-secondary:hover {
  color: white;
}

.btn-outline-primary, .klaro .cookie-modal .cm-btn.cm-btn-info, .klaro .cookie-modal .cm-btn.cm-btn-danger, .klaro .cookie-modal .cm-btn.cm-btn-accept,
.klaro .context-notice .cm-btn.cm-btn-info,
.klaro .context-notice .cm-btn.cm-btn-danger,
.klaro .context-notice .cm-btn.cm-btn-accept,
.klaro .cookie-notice .cm-btn.cm-btn-info,
.klaro .cookie-notice .cm-btn.cm-btn-danger,
.klaro .cookie-notice .cm-btn.cm-btn-accept {
  border-width: 1px;
  border-style: solid;
  background-color: transparent !important;
}
.btn-outline-primary:hover, .klaro .cookie-modal .cm-btn.cm-btn-info:hover, .klaro .cookie-modal .cm-btn.cm-btn-danger:hover, .klaro .cookie-modal .cm-btn.cm-btn-accept:hover,
.klaro .context-notice .cm-btn.cm-btn-info:hover,
.klaro .context-notice .cm-btn.cm-btn-danger:hover,
.klaro .context-notice .cm-btn.cm-btn-accept:hover,
.klaro .cookie-notice .cm-btn.cm-btn-info:hover,
.klaro .cookie-notice .cm-btn.cm-btn-danger:hover,
.klaro .cookie-notice .cm-btn.cm-btn-accept:hover {
  color: #4b5bf1 !important;
}

.card-header .btn-link {
  width: 100%;
  height: 100%;
  padding: 1rem;
  text-align: start;
}

.wpcf7-response-output {
  margin: 2rem 0 1rem;
  padding: 1rem;
}

.ajax-loader {
  display: none;
}

.wpcf7 .screen-reader-response {
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  position: absolute;
  width: 1px;
  height: 1px;
}

.wpcf7-not-valid-tip {
  display: block;
  color: #f00;
  font-size: 1em;
  font-weight: normal;
}

.wpcf7-validation-errors {
  border: 2px solid #f7e700;
}

.wpcf7-display-none {
  display: none;
}

[type=color], [type=date], [type=datetime], [type=datetime-local], [type=email], [type=month], [type=number], [type=password], [type=search], [type=tel], [type=text], [type=time], [type=url], [type=week], input:not([type]), textarea {
  width: 100%;
  border: none;
  border-bottom: solid 2px #4b5bf1;
  outline: 0;
  background-color: transparent;
  color: #343a40;
}
[type=color]:focus, [type=date]:focus, [type=datetime]:focus, [type=datetime-local]:focus, [type=email]:focus, [type=month]:focus, [type=number]:focus, [type=password]:focus, [type=search]:focus, [type=tel]:focus, [type=text]:focus, [type=time]:focus, [type=url]:focus, [type=week]:focus, input:not([type]):focus, textarea:focus {
  border-bottom-color: #00c7e3;
}

::placeholder {
  transition: opacity 0.15s ease;
  color: light;
}

.bg-primary [type=color], .bg-primary [type=date], .bg-primary [type=datetime], .bg-primary [type=datetime-local], .bg-primary [type=email], .bg-primary [type=month], .bg-primary [type=number], .bg-primary [type=password], .bg-primary [type=search], .bg-primary [type=tel], .bg-primary [type=text], .bg-primary [type=time], .bg-primary [type=url], .bg-primary [type=week], .bg-primary input:not([type]), .bg-primary textarea {
  border-bottom: solid 2px white;
  color: white;
}
.bg-primary [type=color]:focus, .bg-primary [type=date]:focus, .bg-primary [type=datetime]:focus, .bg-primary [type=datetime-local]:focus, .bg-primary [type=email]:focus, .bg-primary [type=month]:focus, .bg-primary [type=number]:focus, .bg-primary [type=password]:focus, .bg-primary [type=search]:focus, .bg-primary [type=tel]:focus, .bg-primary [type=text]:focus, .bg-primary [type=time]:focus, .bg-primary [type=url]:focus, .bg-primary [type=week]:focus, .bg-primary input:not([type]):focus, .bg-primary textarea:focus {
  border-bottom-color: #00c7e3;
}
.bg-primary ::placeholder {
  transition: opacity 0.15s ease;
  color: white;
}

:focus::placeholder {
  opacity: 0.5;
}

textarea {
  resize: none;
}

.form-control,
.form-control:focus {
  width: auto;
}

.error404 .search-form {
  display: flex;
  padding-bottom: 2rem;
}

form .um-field-label label {
  color: inherit;
}

.um-login .um-button.um-alt {
  display: none;
}
.um-login .um-half {
  width: 100%;
}

.tribe-events-widget-events-list__view-more-link {
  display: none;
}

.simple-social-icons ul li:first-child {
  margin-left: 0 !important;
}

.tipsy {
  display: none !important;
}

/* Link colors */
.um a.um-link,
.um .um-tip:hover,
.um .um-field-radio.active:not(.um-field-radio-state-disabled) i,
.um .um-field-checkbox.active:not(.um-field-radio-state-disabled) i,
.um .um-member-name a:hover,
.um .um-member-more a:hover,
.um .um-member-less a:hover,
.um .um-members-pagi a:hover,
.um .um-cover-add:hover,
.um .um-profile-subnav a.active,
.um .um-item-meta a,
.um-account-name a:hover,
.um-account-nav a.current,
.um-account-side li a.current span.um-account-icon,
.um-account-side li a.current:hover span.um-account-icon,
.um-dropdown li a:hover,
i.um-active-color,
span.um-active-color {
  color: #00c7e3 !important;
}

.um a.um-link:hover,
.um a.um-link-hvr:hover {
  color: #009ab0 !important;
}

/* Button colors */
.um .um-field-group-head,
.picker__box,
.picker__nav--prev:hover,
.picker__nav--next:hover,
.um .um-members-pagi span.current,
.um .um-members-pagi span.current:hover,
.um .um-profile-nav-item.active a,
.um .um-profile-nav-item.active a:hover,
.upload,
.um-modal-header,
.um-modal-btn,
.um-modal-btn.disabled,
.um-modal-btn.disabled:hover,
div.uimob800 .um-account-side li a.current,
div.uimob800 .um-account-side li a.current:hover,
.um .um-button,
.um a.um-button,
.um a.um-button.um-disabled:hover,
.um a.um-button.um-disabled:focus,
.um a.um-button.um-disabled:active,
.um input[type=submit].um-button,
.um input[type=submit].um-button:focus,
.um input[type=submit]:disabled:hover {
  background: #00c7e3 !important;
}

.um .um-field-group-head:hover,
.picker__footer,
.picker__header,
.picker__day--infocus:hover,
.picker__day--outfocus:hover,
.picker__day--highlighted:hover,
.picker--focused .picker__day--highlighted,
.picker__list-item:hover,
.picker__list-item--highlighted:hover,
.picker--focused .picker__list-item--highlighted,
.picker__list-item--selected,
.picker__list-item--selected:hover,
.picker--focused .picker__list-item--selected,
.um .um-button:hover,
.um a.um-button:hover,
.um input[type=submit].um-button:hover {
  background: #009ab0 !important;
}

/* Reveal Effect
---------------------------------------------------------------------------------------------------- */
.widget_search input[type=submit] {
  z-index: 4;
  width: 80px;
}

.social-share {
  width: 160px;
}

.social-share-buttons {
  width: 80px;
}

.header-widget-area {
  position: absolute;
  right: 0;
  height: 100%;
}

aside.sidebar-primary {
  background-color: #ededed;
}
aside.sidebar-primary .entry {
  background-color: white;
}
aside.sidebar-primary .widget {
  padding: 1rem;
}
aside.sidebar-primary .widget-title {
  color: #343a40;
}
aside.sidebar-primary .widget-title::before {
  content: "";
  display: block;
  width: 2rem;
  height: 5px;
  margin-bottom: 1rem;
  background-color: #4b5bf1;
}

.footer-widgets {
  background: linear-gradient(to right, #4b5bf1, #00c7e3);
}
.footer-widgets ul {
  padding: 0;
}
.footer-widgets ul li,
.footer-widgets .featured-content .entry {
  margin-bottom: 1rem;
}

.bg-primary a {
  color: white;
}
.bg-primary a:hover {
  color: #00c7e3;
}

.site-footer {
  background: linear-gradient(to right, #3345ef, #00b1ca);
  color: white;
}

#accueil-intro {
  position: relative;
}

#slideshow-wrap {
  height: 600px;
}

.cycle-slideshow {
  display: inline-block;
  z-index: 1;
}

.cycle-slideshow img {
  width: 70%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 991.98px) {
  .cycle-slideshow img {
    width: 100%;
  }
}

.cycle-control-panel {
  display: flex;
  position: absolute;
  z-index: 500;
  bottom: 0;
  left: 0;
  flex-direction: column;
  justify-content: center;
  width: 124px;
}
@media (max-width: 991.98px) {
  .cycle-control-panel {
    width: 100%;
  }
}

.cycle-overlay {
  position: absolute;
  z-index: 110;
  top: 50%;
  right: 0;
  width: 50%;
  transform: translateY(-50%);
  background-color: #ffffffde;
}
.cycle-overlay h2::after {
  content: "";
  display: block;
  width: 40%;
  height: 1px;
  margin: 1rem 0;
  transform: translateX(-100%);
  background-color: #00c7e3;
}
@media (max-width: 991.98px) {
  .cycle-overlay h2::after {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .cycle-overlay {
    width: 100%;
  }
}
@media (max-width: 991.98px) {
  .cycle-overlay {
    top: auto;
    bottom: 0;
    transform: none;
    opacity: 0.9;
  }
}

#progress {
  height: 5px;
}

.cycle-controls {
  height: 45px;
}
@media (max-width: 991.98px) {
  .cycle-controls {
    display: none;
  }
}
.cycle-controls #next,
.cycle-controls #prev {
  display: inline-block;
  position: relative;
  width: 60px;
  height: 45px;
  border-bottom-left-radius: 0.25rem !important;
  background-color: white;
  cursor: pointer;
}
.cycle-controls #next::before, .cycle-controls #next::after,
.cycle-controls #prev::before,
.cycle-controls #prev::after {
  content: "";
  position: absolute;
  left: 35%;
  width: 2px;
  height: 30%;
  backface-visibility: hidden;
  transition: background-color 0.3s, opacity 0.3s ease, -webkit-transform 0.2s ease;
  transition: transform 0.2s ease, background-color 0.3s, opacity 0.3s ease;
  background: #adb5bd;
}
.cycle-controls #next::before,
.cycle-controls #prev::before {
  top: 20%;
  transform: translateX(-50%) rotate(45deg);
  transform-origin: 0 100%;
}
.cycle-controls #next::after,
.cycle-controls #prev::after {
  top: 50%;
  transform: translateX(-50%) rotate(-45deg);
  transform-origin: 0 0;
}
.cycle-controls #next:hover,
.cycle-controls #prev:hover {
  background-color: #00c7e3;
}
.cycle-controls #next:hover::before, .cycle-controls #next:hover::after,
.cycle-controls #prev:hover::before,
.cycle-controls #prev:hover::after {
  background-color: white;
}
.cycle-controls #next {
  right: 0;
  transform: rotate(180deg) translateY(-50%);
  transform-origin: 50% 25%;
}

.cycle-controls {
  bottom: 0;
}

#cycle-caption {
  color: #adb5bd;
  font-weight: 600;
  text-align: center;
}
@media (max-width: 991.98px) {
  #cycle-caption {
    display: none !important;
  }
}
#cycle-caption::first-letter {
  color: #00c7e3;
}

.tiret-secondary-top::before,
.tiret-white-top::before,
.tiret-gradient-top::before {
  content: "";
  display: block;
  width: 2rem;
  height: 0.25rem;
  margin-bottom: 1rem;
}

.tiret-secondary-top::before {
  background-color: #00c7e3;
}

.tiret-white-top::before {
  background-color: white;
}

.tiret-gradient-top::before {
  background: linear-gradient(to right, #4b5bf1, #00c7e3);
}

.categorie-thumbs {
  position: absolute;
  top: 0;
  margin: 0;
  padding: 0;
}

.categorie-thumbnail {
  display: inline-block;
  width: 50px;
  height: 50px;
  margin: 1rem 0 1rem 1rem;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
}

.icone-animation {
  background-image: url(../images/sprite.png);
  background-position: 0px 0px;
  width: 80px;
  height: 80px;
}

.icone-appui {
  background-image: url(../images/sprite.png);
  background-position: -80px 0px;
  width: 80px;
  height: 80px;
}

.icone-information {
  background-image: url(../images/sprite.png);
  background-position: -80px -80px;
  width: 80px;
  height: 80px;
}

.icone-formation {
  background-image: url(../images/sprite.png);
  background-position: 0px -80px;
  width: 80px;
  height: 80px;
}

.news {
  flex-direction: column;
}
.news .post-thumbnail {
  width: 100%;
  height: 200px;
  object-fit: cover;
}
.news .post-thumbnail .thumbnail-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.news .post-thumbnail,
.news .entry-header {
  position: relative;
}
.news .entry-title a {
  color: #343a40;
}
.news .entry-title a:hover {
  color: #00c7e3;
}

.post-inner .entry-content {
  word-break: break-word;
}

.entry-footer {
  opacity: 0.5;
  text-align: right;
}

.entry-header .entry-meta,
.sidebar .post-info {
  margin-bottom: 1rem;
  color: #343a40;
  font-size: 0.85rem;
  font-weight: 500;
  text-transform: uppercase;
}

.meta-date::after {
  content: "";
  display: block;
  width: 4rem;
  height: 1px;
  margin-top: 0.25rem;
  background-color: #00c7e3;
}

.sidebar .entry-title {
  margin-bottom: 0.5rem;
}
.collab {
  flex: 1 0 200px;
}

.formation main.content > article header.entry-header {
  display: none;
}

.klaro .cm-klaro {
  border-radius: 0 !important;
}
.klaro .cookie-modal:not(.cookie-modal-notice),
.klaro .context-notice:not(.cookie-modal-notice),
.klaro .cookie-notice:not(.cookie-modal-notice) {
  border-radius: 0;
  background-color: #fff;
}
.klaro .cookie-modal a,
.klaro .context-notice a,
.klaro .cookie-notice a {
  color: #4b5bf1 !important;
}
.klaro .cn-buttons {
  display: flex !important;
  gap: 0.5rem;
}
.klaro .cm-list-input:checked + .cm-list-label .slider,
.klaro .context-notice .cm-list-input:checked + .cm-list-label .slider,
.klaro .cookie-notice .cm-list-input:checked + .cm-list-label .slider {
  background-color: #4b5bf1 !important;
}
.klaro .cm-list-input.only-required + .cm-list-label .slider,
.klaro .context-notice .cm-list-input.only-required + .cm-list-label .slider,
.klaro .cookie-notice .cm-list-input.only-required + .cm-list-label .slider {
  background-color: #7a86f5 !important;
}
.klaro .cookie-modal .cm-modal {
  /* .cm-header,
     .cm-body { @apply p-6; }

     .cm-footer { @apply px-6 py-3; } */
}
.klaro .cookie-modal .cm-modal.cm-klaro {
  background-color: #fff;
}
.klaro .cookie-modal .cm-modal .cm-footer-buttons {
  display: flex;
  gap: 0.5rem;
}
.klaro .cookie-modal .cm-modal .cm-footer-buttons .cm-btn {
  flex: 1 1 0%;
}
.hero-accueil {
  overflow: visible;
}
.hero-accueil .container {
  position: relative;
  z-index: 10;
}
.hero-accueil svg {
  display: none;
  position: absolute;
  z-index: -1;
  top: -40vw;
  left: 0;
}
@charset "UTF-8";
/* Site Header
---------------------------------------------------------------------------------------------------- */
.site-header {
  position: relative;
  z-index: 50;
  top: 0;
  width: 100%;
  transition: transform 0.5s ease, background 0.3s, -webkit-transform 0.5s ease;
  background: white;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.5);
}
.site-header .container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
@media (min-width: 992px) {
  .site-header {
    position: relative;
  }
}

.site-header.hidden {
  transform: translateY(-100%);
}

.site-header.shrink {
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);
}
.site-header.shrink nav a span {
  color: #212529;
}

/* Hero page header
--------------------------------------------- */
.hero-section {
  display: flex;
  position: relative;
  align-items: center;
  min-height: 25vmin;
  background-color: #e8f9fc;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  color: #fff;
  text-align: center;
}
.hero-section::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  background: linear-gradient(to right, #4b5bf1, #00c7e3);
}

.no-thumb .hero-section::before {
  opacity: 1;
}

.parent-pageid-95 .hero-section {
  background-color: white;
  background-size: auto;
}

/* logo
--------------------------------------------- */
.site-title a {
  display: inline-flex;
  width: 186px;
  height: 90px;
  background-image: url("../images/GIRCI-MEDITERRANEE-Logo.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% auto;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
}
@media (max-width: 991.98px) {
  .site-title a {
    width: 130px;
    height: 45px;
  }
}

/* Site Title and Description
--------------------------------------------- */
.site-title {
  margin-top: 0;
  margin-bottom: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 20px;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
}

.header-image .nav-primary:not(button) {
  margin-top: 10px;
}

.header-image .site-header {
  padding: 9px 40px;
}
.header-image .site-header .wrap {
  padding: 0;
}
@media (max-width: 991.98px) {
  .header-image .site-header {
    padding: 0 40px;
  }
}
@media (max-width: 767.98px) {
  .header-image .site-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
@media (max-width: 575.98px) {
  .header-image .site-header {
    padding: 0 20px;
  }
}

.header-image .site-title {
  display: inline;
  text-indent: -9999px;
}

.header-image .site-title > a {
  float: left;
  background-position: center center !important;
  background-size: contain !important;
}

.header-image .title-area {
  margin: 0;
}

.site-description {
  display: block;
  height: 0;
  margin-bottom: 0;
  text-indent: -9999px;
}

/* Site Navigation
---------------------------------------------------------------------------------------------------- */
.genesis-nav-menu {
  width: 100%;
  margin: 0;
  clear: both;
  font-weight: 500;
  line-height: 1;
}

/* Accessible Menu
--------------------------------------------- */
.menu .menu-item:focus {
  position: static;
}

.menu .menu-item > a:focus + ul.sub-menu,
.menu .menu-item.sfHover > ul.sub-menu {
  left: auto;
  opacity: 1;
}

/* Primary Navigation
--------------------------------------------- */
.menu-primary {
  display: flex;
}

.nav-primary .genesis-nav-menu li.last a {
  padding-right: 0;
}

.nav-primary .genesis-nav-menu li li {
  margin-left: 0;
}

/* Secondary Navigation
--------------------------------------------- */
.nav-secondary .genesis-nav-menu a {
  padding: 20px;
}
@media only screen and (max-width: 360px) {
  .nav-secondary .genesis-nav-menu a {
    padding: 20px 10px;
  }
}

/* Responsive Navigation
--------------------------------------------- */
#vertical-navigation {
  display: none;
}

.menu-themes-strategiques,
.menu-temi-strategici {
  display: inline-block;
}

.search-form {
  display: none;
}

.site-header .nav-primary {
  display: none;
  position: fixed;
  top: -1rem;
  right: -1rem;
  bottom: -1rem;
  left: 5vw;
  left: -1rem;
  width: 100vw;
  margin: auto;
  padding: 5vw;
  overflow: auto;
  overflow-x: visible;
  border-top: 0.2rem solid #f0f1f2;
  border-bottom: 0.2rem solid #f0f1f2;
  background: linear-gradient(to right, #4b5bf1, #00c7e3);
  text-transform: uppercase;
}
.site-header .menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  clear: both;
  font-size: 1.75rem;
  text-align: center;
}
.site-header .menu a {
  color: white;
}
.site-header .sub-menu {
  display: none;
  padding: 0;
  clear: both;
  font-size: 1.4rem;
  font-size: 1.5rem;
}
.site-header .sub-menu li {
  width: 100%;
}
.site-header .menu-item {
  display: block;
  position: relative;
  width: 100%;
  padding: 0.5rem 0;
}
.site-header .menu-item a {
  display: inline-block;
  width: auto;
  padding: 0.5rem 0;
  text-decoration: none;
}
.site-header .menu-item.sfHover .sub-menu a:hover {
  color: #fff;
}
.site-header .menu-toggle,
.site-header .sub-menu-toggle {
  border-width: 0;
  border-radius: 0;
  background: none;
  color: #343a40;
  cursor: pointer;
}
.site-header .menu-toggle.activated,
.site-header .sub-menu-toggle.activated {
  color: transparent;
}
.site-header .menu-toggle:hover,
.site-header .menu-toggle:focus,
.site-header .sub-menu-toggle:hover,
.site-header .sub-menu-toggle:focus {
  border-width: 0;
  background: none;
}
.site-header .menu-toggle {
  position: relative;
  position: absolute;
  z-index: 19;
  z-index: 999;
  right: 5vw;
  padding-bottom: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 21px;
  text-transform: uppercase;
}
.site-header .menu-toggle.activated {
  position: fixed;
}
.site-header .menu-toggle.activated span {
  background: 0 0;
}
.site-header .menu-toggle span {
  display: block;
  position: relative;
  width: 1.5rem;
  height: 0.2rem;
  margin-top: 0.9rem;
  margin-left: 0.2em;
  float: right;
  transition: background 0s 0.2s;
  border-radius: 2px;
  background: #343a40;
}
.site-header .menu-toggle.activated span::before {
  top: 0;
  transform: rotate(45deg);
  transition: top 0.2s 0s, -webkit-transform 0.2s 0.2s;
  transition: top 0.2s 0s, transform 0.2s 0.2s;
  transition: top 0.2s 0s, transform 0.2s 0.2s, -webkit-transform 0.2s 0.2s;
}
.site-header .menu-toggle span::before {
  top: -4px;
  top: -0.4rem;
  transition: top 0.2s 0.2s, -webkit-transform 0.2s 0s;
  transition: top 0.2s 0.2s, transform 0.2s 0s;
  transition: top 0.2s 0.2s, transform 0.2s 0s, -webkit-transform 0.2s 0s;
}
.site-header .menu-toggle span::after,
.site-header .menu-toggle span::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  width: 100%;
  height: 0.2rem;
  border-radius: 2px;
  background: #343a40;
}
.site-header .menu-toggle.activated span::before,
.site-header .menu-toggle.activated span::after {
  background-color: white;
}
.site-header .menu-toggle.activated span::after {
  bottom: 0;
  transform: rotate(-45deg);
  transition: bottom 0.2s 0s, -webkit-transform 0.2s 0.2s;
  transition: bottom 0.2s 0s, transform 0.2s 0.2s;
  transition: bottom 0.2s 0s, transform 0.2s 0.2s, -webkit-transform 0.2s 0.2s;
}
.site-header .menu-toggle span::after {
  bottom: -0.4rem;
  transition: bottom 0.2s 0.2s, -webkit-transform 0.2s 0s;
  transition: bottom 0.2s 0.2s, transform 0.2s 0s;
  transition: bottom 0.2s 0.2s, transform 0.2s 0s, -webkit-transform 0.2s 0s;
}
.site-header .sub-menu-toggle {
  position: absolute;
  margin: 0 -1em 0 1em;
  padding: 0.5em;
  background-color: transparent;
}
.site-header .sub-menu-toggle::before {
  content: "";
  display: block;
  margin: 0.2rem 0 -0.2rem;
  border: 0.4rem solid transparent;
  border-top-color: #f6f7f8;
}

@media (min-width: 992px) {
  .site-header .nav-primary {
    display: block;
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    flex: 1;
    width: auto;
    width: 100%;
    margin: 0;
    padding: 0;
    overflow: visible;
    border: none;
    background: transparent;
    font-size: initial;
  }
  .site-header .nav-primary .menu {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: center;
  }
  .site-header .nav-primary .menu a {
    padding: 3rem 0;
  }
  .site-header .nav-secondary .menu {
    flex-direction: row;
    justify-content: end;
  }
  .site-header .menu-item.sfHover > .sub-menu,
.site-header .menu-item:focus > .sub-menu,
.site-header .menu-item:hover > .sub-menu {
    opacity: 1 !important;
    pointer-events: all;
  }
  .site-header .menu {
    text-align: left;
  }
  .site-header .menu a {
    display: block;
    height: 100%;
    color: #343a40;
  }
  .site-header .menu .active > a {
    color: #00c7e3;
  }
  .site-header .menu .sub-menu {
    position: absolute;
    z-index: 2;
    min-width: 14rem;
    border-radius: 0.3rem;
    background: linear-gradient(to top, #4b5bf1, #00c7e3);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
  .site-header .menu .sub-menu a {
    position: relative;
    padding: 1rem;
    color: rgba(255, 255, 255, 0.8);
    text-align: left;
    word-wrap: break-word;
  }
  .site-header .menu-item {
    display: inline-block;
    width: auto;
    padding: 0;
    border-bottom: solid 1px #ffffff52;
    font-size: 0.875rem;
  }
  .site-header .menu-item:last-child {
    border-bottom: none;
  }
  .site-header .menu-item-has-children > a::after {
    content: "▼";
    font-size: 12px;
  }
  .site-header .menu-toggle,
.site-header .sub-menu-toggle {
    display: none;
  }
  .site-header .menu-themes-strategiques,
.site-header .menu-temi-strategici {
    display: none;
  }

  #vertical-navigation {
    display: flex;
  }

  .search-form {
    display: block;
  }
}
.menu-item-has-children {
  cursor: default;
}

#menu-top-menu .menu-item a {
  padding: 0.5rem;
  font-family: Avenir;
}

.btn {
  box-shadow: none;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.btn.loading:hover {
  color: transparent;
}

.btn-secondary:hover {
  color: white;
}

.wpcf7-submit.loading {
  transition: background-position 0s;
  background-image: url(../images/rolling.svg);
  background-repeat: no-repeat;
  background-position: center center;
  color: transparent;
}

.btn-lg {
  padding: 0.8rem 2.5rem;
}

.btn-primary:focus, .btn-primary.focus,
.btn-secondary:focus,
.btn-secondary.focus {
  box-shadow: none;
}
.btn-primary:hover,
.btn-secondary:hover {
  color: white;
}

.btn-outline-primary {
  border-width: 1px;
  border-style: solid;
  background-color: transparent !important;
}
.btn-outline-primary:hover {
  color: #4b5bf1 !important;
}

.card-header .btn-link {
  width: 100%;
  height: 100%;
  padding: 1rem;
  text-align: start;
}

.content {
  width: 100%;
}

.transition-overlay {
  display: block;
  position: fixed;
  z-index: 60;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
}

.page .page-categories {
  display: none;
}

.page-categories {
  display: flex;
  align-items: center;
  background-color: #2a3dee;
}
.page-categories .post-categories {
  margin-bottom: 0;
  padding: 0;
}
.page-categories .post-categories li {
  display: inline;
}
.page-categories .post-categories li a {
  color: white;
}
.page-categories .post-categories li::before {
  content: "#";
  color: #00c7e3;
}