// [class*="col-"] {
//   background-color: rgba(86, 61, 124, .15);
//   border: 1px solid rgba(86, 61, 124, 0.15);
// }
#accueil-intro {
	position: relative;
}

#slideshow-wrap {
	height: 600px;
}

.cycle-slideshow {
	display: inline-block;
	z-index: 1;
}

.cycle-slideshow {

	img {
		width: 70%;
		height: 100%;
		object-fit: cover;
		@include media-breakpoint-down(md) {
			width: 100%;
		}
	}
}


.cycle-control-panel {
	display: flex;
	position: absolute;
	z-index: 500;
	bottom: 0;
	left: 0;
	flex-direction: column;
	justify-content: center;
	width: 124px;
	@include media-breakpoint-down(md) {
		width: 100%;
	}
}


.cycle-overlay {
	position: absolute;
	z-index: 110;
	top: 50%;
	right: 0;
	width: 50%;
	transform: translateY(-50%);
	background-color: #ffffffde;

	h2::after {
		content: "";
		display: block;
		width: 40%;
		height: 1px;
		margin: 1rem 0;
		transform: translateX(-100%);
		background-color: map-get($theme-colors, secondary);
		@include media-breakpoint-down(md) {
			display: none;
		}
	}
	@include media-breakpoint-down(lg) {
		width: 100%;
	}
	@include media-breakpoint-down(md) {
		top: auto;
		bottom: 0;
		transform: none;
		opacity: 0.9;
	}
}


#progress {
	height: 5px;
}

.cycle-controls {
	height: 45px;
	@include media-breakpoint-down(md) {
		display: none;
	}

	#next,
	#prev {
		display: inline-block;
		position: relative;
		width: 60px;
		height: 45px;
		border-bottom-left-radius: $border-radius !important;
		background-color: white;
		cursor: pointer;

		&::before,
		&::after {
			content: "";
			position: absolute;
			left: 35%;
			width: 2px;
			height: 30%;
			backface-visibility: hidden;
			transition: background-color 0.3s, opacity 0.3s ease, -webkit-transform 0.2s ease;
			transition: transform 0.2s ease, background-color 0.3s, opacity 0.3s ease;
			background: $gray-500;
		}

		&::before {
			top: 20%;
			transform: translateX(-50%) rotate(45deg);
			transform-origin: 0 100%;
		}

		&::after {
			top: 50%;
			transform: translateX(-50%) rotate(-45deg);
			transform-origin: 0 0;
		}

		&:hover {
			background-color: map-get($theme-colors, secondary);

			&::before,
			&::after {
				background-color: white;
			}
		}
	}

	#next {
		right: 0;
		transform: rotate(180deg) translateY(-50%);
		transform-origin: 50% 25%;
	}
}


.cycle-controls {
	bottom: 0;
}

#cycle-caption {
	color: $gray-500;
	font-weight: 600;
	text-align: center;
	@include media-breakpoint-down(md) {
		display: none !important;
	}

	&::first-letter {
		color: map-get($theme-colors, secondary);
	}
}


.tiret-secondary-top,
.tiret-white-top,
.tiret-gradient-top {

	&::before {
		content: "";
		display: block;
		width: 2rem;
		height: 0.25rem;
		margin-bottom: 1rem;
	}
}

.tiret-secondary-top::before {
	background-color: map-get($theme-colors, secondary);
}

.tiret-white-top::before {
	background-color: white;
}

.tiret-gradient-top::before {
	background: $h-gradient;
}


.categorie-thumbs {
	position: absolute;
	top: 0;
	margin: 0;
	padding: 0;
}

.categorie-thumbnail {
	display: inline-block;
	width: 50px;
	height: 50px;
	margin: 1rem 0 1rem 1rem;
	@include hide-text;
}


.icone-animation {
	@include sprite($animation);
}

.icone-appui {
	@include sprite($appui);
}

.icone-information {
	@include sprite($information);
}

.icone-formation {
	@include sprite($formation);
}
