@import "../../node_modules/bourbon/core/bourbon";
@import "common/_variables";
@import "layouts/header";
@import "components/_buttons";

.content {
	width: 100%;
}

.transition-overlay {
	display: block;
	position: fixed;
	z-index: 60;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: #fff;
}


.page .page-categories {
	display: none;
}

.page-categories {
	display: flex;
	align-items: center;
	background-color: darken(map-get($theme-colors, primary), 7%);

	.post-categories {
		margin-bottom: 0;
		padding: 0;

		li {
			display: inline;

			a {
				color: white;
			}

			&::before {
				content: "#";
				color: map-get($theme-colors, secondary);
			}
		}
	}
}
