// Metas
// .entry-time {
//  text-transform: capitalize;
// }
.news {
	flex-direction: column;

	.post-thumbnail {
		width: 100%;
		height: 200px;
		object-fit: cover;

		.thumbnail-image {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}


	.post-thumbnail,
	.entry-header {
		position: relative;
	}

	.entry-title {

		a {
			color: map-get($theme-colors, dark);

			&:hover {
				color: map-get($theme-colors, secondary);
			}
		}
	}
}


// add_filter('genesis_attr_post-inner', 'add_bg_white');
// add_filter('genesis_attr_entry-header', 'add_px_4');
// add_filter('genesis_attr_entry-header', 'add_pt_4');
// add_filter('genesis_attr_entry-content', 'add_px_4');
// add_filter('genesis_attr_post-inner', 'add_rounded_bottom');
// add_filter('genesis_attr_post-inner', 'add_shadow_sm');

.post-inner {
	@extend .rounded-bottom;
	@extend .shadow-sm;
	@extend .bg-white;

	.entry-header {
		@extend .pt-4;
		@extend .px-4;
	}

	.entry-content {
		@extend .px-4;
		@extend .pb-3;

		word-break: break-word;
	}
}

.entry-footer {
	@extend .px-4;

	opacity: 0.5;
	text-align: right;
}

.entry-header .entry-meta,
.sidebar .post-info {
	margin-bottom: 1rem;
	color: map-get($theme-colors, dark);
	font-size: 0.85rem;
	font-weight: 500;
	text-transform: uppercase;
}

.meta-date {

	&::after {
		content: "";
		display: block;
		width: 4rem;
		height: 1px;
		margin-top: 0.25rem;
		background-color: map-get($theme-colors, secondary);
	}
}

.sidebar .entry-title {
	margin-bottom: 0.5rem;

	a {
		@extend h4;
	}
}
