@import "../node_modules/klaro/dist/klaro.css";

.klaro {

	.cm-klaro {
		border-radius: 0 !important;
	}

	.cookie-modal,
	.context-notice,
	.cookie-notice {

		&:not(.cookie-modal-notice) {
			border-radius: 0;
			background-color: #fff;
		}

		a {
			color: map-get($theme-colors, primary) !important;
		}

		.cm-btn {
			@extend .btn;
			@extend .btn-sm;

			&.cm-btn {

				&-info,
				&-danger,
				&-accept {

					@extend .btn-outline-primary;
				}

				&-accept-all,
				&-success:not(.cm-btn-info) {

					@extend .btn-primary;
				}
			}
		}

	}

	.cn-buttons {
		display: flex !important;
		gap: 0.5rem;
	}

	.cm-list-input:checked + .cm-list-label .slider,
	.context-notice .cm-list-input:checked + .cm-list-label .slider,
	.cookie-notice .cm-list-input:checked + .cm-list-label .slider, {
		background-color: map-get($theme-colors, primary) !important;
	}

	.cm-list-input.only-required + .cm-list-label .slider,
	.context-notice .cm-list-input.only-required + .cm-list-label .slider,
	.cookie-notice .cm-list-input.only-required + .cm-list-label .slider {
		background-color: lighten(map-get($theme-colors, primary), 10%) !important;
	}

	.cookie-modal {

		.cm-modal {

			&.cm-klaro {
				background-color: #fff;
			}

			.cm-body {

				ul.cm-services,
				ul.cm-purposes {

					li.cm-service.cm-toggle-all,
					li.cm-purpose.cm-toggle-all {
						// @apply border-t-light;
					}
				}
			}

			.cm-header {
				// @apply border-b-light;
			}

			.cm-footer {
				// @apply border-t-light;
			}

			/* .cm-header,
      .cm-body { @apply p-6; }

      .cm-footer { @apply px-6 py-3; } */

			.cm-footer-buttons {
				display: flex;
				gap: 0.5rem;

				.cm-btn {
					flex: 1 1 0%;
				}
			}
		}
	}
}
