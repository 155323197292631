@import "components/_font-awesome";

@font-face {
	font-family: "Avenir";

	/* Legacy iOS */
	font-weight: 400;

	/* IE9 Compat Modes */
	src: url("../fonts/AvenirNextLTPro-Regular.woff2") format("woff2"),
		url("../fonts/AvenirNextLTPro-Regular.woff") format("woff");
}

@font-face {
	font-family: "Avenir";

	/* Legacy iOS */
	font-weight: 500;
	src: url("../fonts/AvenirNextLTPro-Medium.eot");

	/* IE9 Compat Modes */
	src: url("../fonts/AvenirNextLTPro-Medium.woff2") format("woff2"),
		url("../fonts/AvenirNextLTPro-Medium.woff") format("woff");
}

@font-face {
	font-family: "Avenir";

	/* Legacy iOS */
	font-weight: 600;

	/* IE9 Compat Modes */
	src: url("../fonts/AvenirNextLTPro-Demi.woff2") format("woff2"),
		url("../fonts/AvenirNextLTPro-Demi.woff") format("woff");
}

@font-face {
	font-family: "Avenir";

	/* Legacy iOS */
	font-weight: 700;

	/* IE9 Compat Modes */
	src: url("../fonts/AvenirNextLTPro-Bold.woff2") format("woff2"),
		url("../fonts/AvenirNextLTPro-Bold.woff") format("woff");
}

@font-face {
	font-family: "Avenir";

	/* Legacy iOS */
	font-weight: 700;

	/* IE9 Compat Modes */
	src: url("../fonts/AvenirNextLTPro-Bold.woff2") format("woff2"),
		url("../fonts/AvenirNextLTPro-Bold.woff");
}

@font-face {
	font-family: "Univia";

	/* Legacy iOS */
	font-weight: 500;
	src:		url("../fonts/UniviaProRegular.woff2") format("woff2"),
		url("../fonts/UniviaProRegular.woff") format("woff");
}

// @font-face {
// 	font-family: "Univia";

// 	/* Legacy iOS */
// 	font-weight: 700;
// 	src: url("../fonts/UniviaPro-Bold.eot");

// 	/* IE9 Compat Modes */
// 	src: url("../fonts/UniviaPro-Bold.eot?#iefix") format("embedded-opentype"),
// 		url("../fonts/UniviaPro-Bold.woff2") format("woff2"),
// 		url("../fonts/UniviaPro-Bold.woff") format("woff"),
// 		url("../fonts/UniviaPro-Bold.ttf") format("truetype"),
// 		url("../fonts/UniviaPro-Bold.svg#UniviaPro-Bold") format("svg");
// }

body {
	font-family: "Avenir";
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	color: map-get($colors, gray-dark);
	font-weight: 500;
}

h1,
h2,
h3,
h4,
.h1,
.h2,
.h3,
.h4 {
	font-family: "Univia";
}

h6,
.h6 {
	letter-spacing: 1px;
}


h5,
.h5,
h6,
.h6 {
	color: map-get($theme-colors, primary);
}

h5,
.h5,
h6,
.h6 {
	font-family: "Avenir";
}

//  html {
//   font-size: 14px;
// }
// @include media-breakpoint-up(sm) {
//   html {
//     font-size: 16px;
//   }
// }
// @include media-breakpoint-up(md) {
//   html {
//     font-size: 20px;
//   }
// }
// @include media-breakpoint-up(lg) {
//   html {
//     font-size: 28px;
//   }
// }
.display-4 {
	font-weight: 500;
}

.display- {

	&4,
	&3,
	&2,
	&1 {}
}

.menu-item {
	font-family: "Univia";
	-webkit-font-smoothing: antialiased;

	// -webkit-text-stroke: 0.35px;
	-moz-osx-font-smoothing: grayscale;
}
