@import "layouts/pages/_accueil";
@import "layouts/pages/_blog";
@import "layouts/pages/_404";
@import "layouts/pages/_contact";
@import "layouts/pages/_equipe";
@import "layouts/pages/_fleet";
@import "layouts/pages/_service";
@import "layouts/pages/_services";

.collab {
  flex: 1 0 200px;
}

.formation {
  main.content>article header.entry-header {
    display: none;
  }
}
