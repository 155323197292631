.simple-social-icons ul li:first-child {
  margin-left: 0 !important;
}

.tipsy {
  display: none !important;
}

/* Link colors */
.um a.um-link,
.um .um-tip:hover,
.um .um-field-radio.active:not(.um-field-radio-state-disabled) i,
.um .um-field-checkbox.active:not(.um-field-radio-state-disabled) i,
.um .um-member-name a:hover,
.um .um-member-more a:hover,
.um .um-member-less a:hover,
.um .um-members-pagi a:hover,
.um .um-cover-add:hover,
.um .um-profile-subnav a.active,
.um .um-item-meta a,
.um-account-name a:hover,
.um-account-nav a.current,
.um-account-side li a.current span.um-account-icon,
.um-account-side li a.current:hover span.um-account-icon,
.um-dropdown li a:hover,
i.um-active-color,
span.um-active-color
{
    color: map-get($theme-colors, secondary) !important;
}

.um a.um-link:hover,
.um a.um-link-hvr:hover {
    color: darken(map-get($theme-colors, secondary),10%) !important;
}

/* Button colors */
.um .um-field-group-head,
.picker__box,
.picker__nav--prev:hover,
.picker__nav--next:hover,
.um .um-members-pagi span.current,
.um .um-members-pagi span.current:hover,
.um .um-profile-nav-item.active a,
.um .um-profile-nav-item.active a:hover,
.upload,
.um-modal-header,
.um-modal-btn,
.um-modal-btn.disabled,
.um-modal-btn.disabled:hover,
div.uimob800 .um-account-side li a.current,
div.uimob800 .um-account-side li a.current:hover,
.um .um-button,
.um a.um-button,
.um a.um-button.um-disabled:hover,
.um a.um-button.um-disabled:focus,
.um a.um-button.um-disabled:active,
.um input[type=submit].um-button,
.um input[type=submit].um-button:focus,
.um input[type=submit]:disabled:hover
{
    background: map-get($theme-colors, secondary) !important;
}

.um .um-field-group-head:hover,
.picker__footer,
.picker__header,
.picker__day--infocus:hover,
.picker__day--outfocus:hover,
.picker__day--highlighted:hover,
.picker--focused .picker__day--highlighted,
.picker__list-item:hover,
.picker__list-item--highlighted:hover,
.picker--focused .picker__list-item--highlighted,
.picker__list-item--selected,
.picker__list-item--selected:hover,
.picker--focused .picker__list-item--selected,
.um .um-button:hover,
.um a.um-button:hover,
.um input[type=submit].um-button:hover{
    background: darken(map-get($theme-colors, secondary),10%) !important;
}

// /* Button alt colors */
// .um .um-button.um-alt, 
// .um input[type=submit].um-button.um-alt {
//     background: #eeeeee !important;
// }

// .um .um-button.um-alt:hover,
// .um input[type=submit].um-button.um-alt:hover {
//     background: #e5e5e5 !important;	
// }