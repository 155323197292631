.wpcf7-response-output {
	margin: 2rem 0 1rem;
	padding: 1rem;
}

.ajax-loader {
	display: none;
}

.wpcf7 .screen-reader-response {
	@include hide-text;

	position: absolute;
	width: 1px;
	height: 1px;
}

.wpcf7-not-valid-tip {
	display: block;
	color: #f00;
	font-size: 1em;
	font-weight: normal;
}

.wpcf7-validation-errors {
	border: 2px solid #f7e700;
}

.wpcf7-display-none {
	display: none;
}

#{$all-text-inputs} {
	width: 100%;
	border: none;
	border-bottom: solid 2px map-get($theme-colors, primary);
	outline: 0;
	background-color: transparent;
	color: map-get($theme-colors, dark);

	&:focus {
		border-bottom-color: map-get($theme-colors, secondary);
	}
}

::placeholder {
	transition: opacity 0.15s ease;
	color: light;
}

.bg-primary {
	#{$all-text-inputs} {
		border-bottom: solid 2px white;
		color: white;

		&:focus {
			border-bottom-color: map-get($theme-colors, secondary);
		}
	}

	::placeholder {
		transition: opacity 0.15s ease;
		color: white;
	}
}

:focus::placeholder {
	opacity: 0.5;
}

textarea {
	resize: none;
}

.form-control,
.form-control:focus {
	width: auto; // background-color: map-get($theme-colors, secondary);
	// color: white;
}

.error404 .search-form {
	display: flex;
	padding-bottom: $spacer * 2;
}

form .um-field-label label {
	color: inherit;
}

.um-login {

	.um-button.um-alt {
		display: none;
	}

	.um-half {
		width: 100%;
	}
}
