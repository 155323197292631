@import "common/_variables";
@import "../../node_modules/bootstrap/scss/_functions";
@import "../../node_modules/bootstrap/scss/_variables";
@import "../../node_modules/bootstrap/scss/_mixins";

.hero-accueil {
	// position: relative;
	// text-align: left;
	// display: flex;
	// align-items: center;
	// justify-content: center;
	overflow: visible;

	.container {
		position: relative;
		z-index: 10;
	}

	svg {
		display: none;
		position: absolute;
		z-index: -1;
		top: -40vw;
		left: 0;
	}
}

// .hero-arc {
//   height: 100%;
//   width: 100%;
//   position: absolute; // top: -1200px;
//   // left: -1240px;
//   // border-radius: 50%;
//   top: 0;
//   background: white;
//   &:after {
//     height: calc(100%+110px);
//     width: calc(100%+110px);
//     content: "";
//     background: red;
//   }
// }
