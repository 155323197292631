@import "../common/variables";
@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";
@import "../../../node_modules/bourbon/core/bourbon";


/* Site Header
---------------------------------------------------------------------------------------------------- */

.site-header {
	position: relative;
	z-index: 50;
	top: 0;
	width: 100%;
	transition: transform 0.5s ease, background 0.3s, -webkit-transform 0.5s ease;
	background: white;
	box-shadow: 0 0 1px 0 rgb(0 0 0 / 50%);

	.container {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
	}
	@include media-breakpoint-up(lg) {
		position: relative;
	}
}


.site-header.hidden {
	transform: translateY(-100%);
}

.site-header.shrink {
	box-shadow: 0 0 3px 0 rgb(0 0 0 / 50%);

	nav a span {
		color: $body-color;
	}
} // .admin-bar .site-header {
//   top: 32px;
// }

/* Hero page header
--------------------------------------------- */

.hero-section {
	display: flex;
	position: relative;
	align-items: center;
	min-height: 25vmin;
	background-color: map-get($theme-colors, light-blue);
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	color: #fff; //padding: 120px 0 60px;
	text-align: center;


	&::before {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0.8;
		background: $h-gradient;
	}
}

.no-thumb .hero-section {

	&::before {
		opacity: 1;
	}
}

.parent-pageid-95 .hero-section {
	background-color: white;
	background-size: auto;
}


/* logo
--------------------------------------------- */

.site-title a {
	display: inline-flex;
	width: 186px;
	height: 90px;
	background-image: url("../images/GIRCI-MEDITERRANEE-Logo.png");
	background-repeat: no-repeat;
	background-position: center;
	background-size: 100% auto;
	@include hide-text;
	@include media-breakpoint-down(md) {
		width: 130px;
		height: 45px;
	}
}


/* Site Title and Description
--------------------------------------------- */

.site-title {
	margin-top: 0;
	margin-bottom: 0;
	font-family: $font-family-sans-serif;
	font-size: 20px;
	font-size: 2rem;
	font-weight: 700;
	line-height: 1;
	text-transform: uppercase;
}


.header-image .nav-primary:not(button) {
	margin-top: 10px;
}

.header-image .site-header {
	padding: 9px 40px;

	.wrap {
		padding: 0;
	}
	@include media-breakpoint-down(md) {
		padding: 0 40px;
	}
	@include media-breakpoint-down(sm) {
		display: flex;
		flex-direction: row;
		justify-content: center;
	}
	@include media-breakpoint-down(xs) {
		padding: 0 20px;
	}
} // .header-image .site-header.shrink {
//     padding: 0 40px;
// }
.header-image .site-title {
	display: inline;
	text-indent: -9999px;
}

.header-image .site-title > a {
	float: left;
	background-position: center center !important;
	background-size: contain !important;
}

.header-image .title-area {
	margin: 0;
}

.site-description {
	display: block;
	height: 0;
	margin-bottom: 0;
	text-indent: -9999px;
}


/* Site Navigation
---------------------------------------------------------------------------------------------------- */

.genesis-nav-menu {
	width: 100%;
	margin: 0;
	clear: both;
	font-weight: 500;
	line-height: 1;
} // .genesis-nav-menu .menu-item {
//     display: inline-block;
//     text-align: left;
// }
// .genesis-nav-menu a {
//     border-bottom-width: 0;
//     color: $black;
//     display: block;
//     font-family: $main-font;
//     @include rem(font-size, 1.4rem);
//     font-weight: 600;
//     letter-spacing: 0.5px;
//     padding: 20px;
//     text-transform: uppercase;
// }
// .genesis-nav-menu a:focus,
// .genesis-nav-menu a:hover {
//     color: $couleur2;
// }
// .genesis-nav-menu .sub-menu {
//     background-color: #fff;
//     left: -9999px;
//     opacity: 0;
//     position: absolute;
// }
// .genesis-nav-menu .sub-menu,
// .genesis-nav-menu .sub-menu a {
//     width: 180px;
// }
// .genesis-nav-menu .sub-menu .sub-menu {
//     margin: -44px 0 0 179px;
// }
// .genesis-nav-menu .sub-menu a {
//     border: 1px solid #eee;
//     border-top: 0;
//     padding: 15px;
//     position: relative;
// }
// .genesis-nav-menu .menu-item:hover {
//     position: static;
// }
// .genesis-nav-menu .menu-item:hover>.sub-menu {
//     left: auto;
//     opacity: 1;
// }
// .header-image {
//     .nav-primary:not(button),
//     .nav-secondary {
//         @include media-breakpoint-down(sm) {
//             margin-top: 54px;
//         }
//     }
// }

/* Accessible Menu
--------------------------------------------- */

.menu .menu-item:focus {
	position: static;
}

.menu .menu-item > a:focus + ul.sub-menu,
.menu .menu-item.sfHover > ul.sub-menu {
	left: auto;
	opacity: 1;
}


/* Primary Navigation
--------------------------------------------- */

// .nav-primary {
//   float: right;
// }
.menu-primary {
	display: flex;
}

.nav-primary .genesis-nav-menu li.last a {
	padding-right: 0;
}


.nav-primary .genesis-nav-menu li li {
	margin-left: 0;
} // ul#menu-left,
// ul#menu-right {
//     @include media-breakpoint-down(sm) {
//         display: flex;
//         justify-content: center;
//     }
// }

/* Secondary Navigation
--------------------------------------------- */


.nav-secondary .genesis-nav-menu a {
	padding: 20px;
	@media only screen and (max-width: 360px) {
		padding: 20px 10px;
	}
}


/* Responsive Navigation
--------------------------------------------- */

#vertical-navigation {
	display: none;
}

.menu-themes-strategiques,
.menu-temi-strategici {
	display: inline-block;
}

.search-form {
	display: none;
}

.site-header {

	.nav-primary {
		display: none;
		position: fixed;
		top: -1rem;
		right: -1rem;
		bottom: -1rem;
		left: 5vw;
		left: -1rem;
		width: 100vw;
		margin: auto;
		padding: 5vw;
		overflow: auto;
		overflow-x: visible;
		border-top: 0.2rem solid #f0f1f2;
		border-bottom: 0.2rem solid #f0f1f2;
		background: $h-gradient;
		text-transform: uppercase;
	}

	.menu {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		min-height: 100%;
		clear: both;
		font-size: $h3-font-size;
		text-align: center;

		a {
			color: white;
		}
	}

	.sub-menu {
		display: none;
		padding: 0;
		clear: both;
		font-size: 1.4rem;
		font-size: $h4-font-size;

		li {
			width: 100%;
		}
	}

	.menu-item {
		display: block;
		position: relative; //font-size: $h3-font-size;
		width: 100%;
		padding: $spacer*0.5 0;

		a {
			display: inline-block;
			width: auto; //display: inline-block;
			padding: $spacer*0.5 0;
			text-decoration: none;
		}

		&.sfHover .sub-menu a:hover {
			color: #fff;
		}
	} // .genesis-nav-menu a {
	//   color: map-get($theme-colors, primary);
	//   text-decoration: none;
	//   display: block;
	// }
	// .genesis-nav-menu a:hover,
	// .genesis-nav-menu li.sfHover a,
	// .genesis-nav-menu a:focus,
	// .genesis-nav-menu .current-menu-item>a,
	// .genesis-nav-menu .sub-menu .current-menu-item>a:hover,
	// .genesis-nav-menu .sub-menu .current-menu-item>a:focus {
	//   background-color: map-get($theme-colors, primary);
	//   color: white;
	// }
	// .genesis-nav-menu .sub-menu {
	//   left: -9999px;
	//   opacity: 0;
	//   position: absolute;
	//   transition: opacity 0.4s ease-in-out;
	//   width: 100%;
	//   padding-left: 0;
	//   li {
	//     width: 100%;
	//   }
	// }
	.menu-toggle,
	.sub-menu-toggle {
		border-width: 0;
		border-radius: 0;
		background: none;
		color: map-get($theme-colors, dark);
		cursor: pointer;

		&.activated {
			color: transparent;
		}
	}

	.menu-toggle:hover,
	.menu-toggle:focus,
	.sub-menu-toggle:hover,
	.sub-menu-toggle:focus {
		border-width: 0;
		background: none;
	}

	.menu-toggle {
		position: relative;
		position: absolute;
		z-index: 19;
		z-index: 999;
		right: 5vw;
		padding-bottom: 0;
		font-family: $font-family-sans-serif;
		font-size: 21px;
		text-transform: uppercase;
	} // .menu-toggle:before,
	// .menu-toggle.activated:before {
	//   color: #1e1e1e;
	//   content: "\f130";
	//   display: inline-block;
	//   font: normal 20px/1 'ionicons';
	//   margin: 0 auto;
	//   padding-right: 10px;
	//   text-rendering: auto;
	//   transform: translate(0, 0);
	//   vertical-align: middle;
	// }
	.menu-toggle.activated {
		position: fixed;

		span {
			background: 0 0;
		}
	}

	.menu-toggle span {
		display: block;
		position: relative;
		width: 1.5rem;
		height: 0.2rem;
		margin-top: 0.9rem;
		margin-left: 0.2em;
		float: right;
		transition: background 0s 0.2s;
		border-radius: 2px;
		background: map-get($theme-colors, dark);
	}

	.menu-toggle.activated span::before {
		top: 0;
		transform: rotate(45deg);
		transition: top 0.2s 0s, -webkit-transform 0.2s 0.2s;
		transition: top 0.2s 0s, transform 0.2s 0.2s;
		transition: top 0.2s 0s, transform 0.2s 0.2s, -webkit-transform 0.2s 0.2s;
	}

	.menu-toggle span::before {
		top: -4px;
		top: -0.4rem;
		transition: top 0.2s 0.2s, -webkit-transform 0.2s 0s;
		transition: top 0.2s 0.2s, transform 0.2s 0s;
		transition: top 0.2s 0.2s, transform 0.2s 0s, -webkit-transform 0.2s 0s;
	}

	.menu-toggle span::after,
	.menu-toggle span::before {
		content: " ";
		display: block;
		position: absolute;
		left: 0;
		width: 100%;
		height: 0.2rem;
		border-radius: 2px;
		background: map-get($theme-colors, dark);
	}

	.menu-toggle.activated span::before,
	.menu-toggle.activated span::after {
		background-color: white;
	}

	.menu-toggle.activated span::after {
		bottom: 0;
		transform: rotate(-45deg);
		transition: bottom 0.2s 0s, -webkit-transform 0.2s 0.2s;
		transition: bottom 0.2s 0s, transform 0.2s 0.2s;
		transition: bottom 0.2s 0s, transform 0.2s 0.2s, -webkit-transform 0.2s 0.2s;
	}

	.menu-toggle span::after {
		bottom: -0.4rem;
		transition: bottom 0.2s 0.2s, -webkit-transform 0.2s 0s;
		transition: bottom 0.2s 0.2s, transform 0.2s 0s;
		transition: bottom 0.2s 0.2s, transform 0.2s 0s, -webkit-transform 0.2s 0s;
	}

	.sub-menu-toggle {
		position: absolute;
		margin: 0 -1em 0 1em;
		padding: 0.5em;
		background-color: transparent;

		&::before {
			content: "";
			display: block;
			margin: 0.2rem 0 -0.2rem;
			border: 0.4rem solid transparent;
			border-top-color: #f6f7f8;
		}
	}
}


@include media-breakpoint-up(lg) {

	.site-header {

		.nav-primary {
			display: block;
			position: relative;
			top: auto;
			right: auto;
			bottom: auto;
			left: auto;
			flex: 1;
			width: auto;
			width: 100%;
			margin: 0;
			padding: 0;
			overflow: visible;
			border: none;
			background: transparent;
			font-size: initial;

			.menu {
				flex-direction: row; // justify-content: center;
				align-items: center;
				justify-content: space-between;
				text-align: center;

				a {
					padding: map-get($spacers, 5) 0;
				}
			}
		}

		.nav-secondary {

			.menu {
				flex-direction: row;
				justify-content: end;
			}
		}

		.menu-item.sfHover > .sub-menu,
		.menu-item:focus > .sub-menu,
		.menu-item:hover > .sub-menu {
			opacity: 1 !important;
			pointer-events: all;
		}

		.menu {
			text-align: left;

			a {
				display: block;
				height: 100%;
				color: map-get($theme-colors, dark);
			}

			.active > a {
				color: map-get($theme-colors, secondary);
			}

			.sub-menu {
				position: absolute;
				z-index: 2;
				min-width: 14rem;
				border-radius: $border-radius-lg;
				background: $v-gradient;
				box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);

				a {
					position: relative;
					padding: 1rem;
					color: rgb(255 255 255 / 80%);
					text-align: left;
					word-wrap: break-word;
				}

				// &:before {
				//   content: "";
				//   width: 10px;
				//   height: 10px;
				//   background-color: map-get($theme-colors, secondary);
				//   position: absolute;
				//   top: 0;
				//   left: 50px;
				//   transform: rotate(45deg) translateY(-50%);
				//   z-index: 1
				// }
			}
		}


		.menu-item {
			display: inline-block;

			// flex-grow: 1; // &.sfHover {
			width: auto;
			padding: 0;

			//   a {
			//     color: map-get($theme-colors, primary);
			//   }
			// }.
			border-bottom: solid 1px #ffffff52;
			font-size: $font-size-sm;

			&:last-child {
				border-bottom: none;
			}
		}

		.menu-item-has-children > a::after {
			content: "▼";

			//margin-left: 0.5rem;
			font-size: 12px;
		}

		.menu-toggle,
		.sub-menu-toggle {
			display: none;
		} // @media (min-width: 896px) {
		//   .menu-toggle {
		//     display: none;
		//   }
		// }
		// .menu-toggle {
		//   padding: 27px 0;
		//   padding: 2.7rem 0;
		//   cursor: pointer;
		//   background-color: transparent;
		//   display: block;
		//   position: absolute;
		//   right: 5vw;
		//   top: 0;
		//   line-height: 1;
		//   color: transparent;
		//   letter-spacing: -.5em;
		//   z-index: 999;
		// }
		.menu-themes-strategiques,
		.menu-temi-strategici {
			display: none;
		}
	}

	#vertical-navigation {
		display: flex;
	}

	.search-form {
		display: block;
	}
}

.menu-item-has-children {
	cursor: default;
}

#menu-top-menu .menu-item a {
	padding: 0.5rem;
	font-family: Avenir;
}
