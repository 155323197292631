.btn {
	box-shadow: none;
	letter-spacing: 2px;
	text-transform: uppercase;


	&.loading:hover {
		color: transparent;
	}
}

.btn-secondary {

	&:hover {
		color: white;
	}
}

.wpcf7-submit.loading {
	transition: background-position 0s;
	background-image: url(../images/rolling.svg);
	background-repeat: no-repeat;
	background-position: center center;
	color: transparent;
}


.btn-lg {
	padding: 0.8rem 2.5rem;
}

.btn-primary,
.btn-secondary {
	// color: white;

	&:focus,
	&.focus {
		box-shadow: none;
	}


	&:hover {
		color: white;
	}
}


.btn-outline-primary {
	border-width: 1px;
	border-style: solid;
	background-color: transparent !important;

	&:hover {
		color: map-get($theme-colors, primary) !important;
	}
}

// .btn-link {
//   color: map-get($theme-colors, primary);
//   font-weight: 500;

//   &:hover {
//     color: map-get($theme-colors, primary);
//   }
// }

.card-header {

	.btn-link {
		width: 100%;
		height: 100%;
		padding: map-get($spacers, 3);
		text-align: start;
	}
}
